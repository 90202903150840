import { createRouter, createWebHistory } from 'vue-router'
import { lgnInf } from "@/state"

const routes = [
    {
        path: '/',
        name: 'LoginComp',
        component: () => import('../views/LoginComp.vue')
    },
    {
        path: '/tpl001',
        name: 'MainComp',
        beforeEnter: (to, from, next) => {
            const lgn = lgnInf()
           
            if(lgn.token != '' && lgn.token != null)
                next()
            else         
                next({ name: 'LoginComp' })
        },
        component: () => import('../views/MainComp.vue'),
        children: [
            {
                // YYS 공지사항
                path: '/yys/news',
                name: 'NewsMgmt',
                component: () => import('../components/yys/NewsMgmt.vue')
            },
            {
                // YYS Home 알림
                path: '/yys/notice',
                name: 'NoticeMgmt',
                component: () => import('../components/yys/NoticeMgmt.vue')
            },
            {
                // YYS 메뉴얼
                path: '/yys/mnl',
                name: 'MnlMgmt',
                component: () => import('../components/yys/MnlMgmt.vue')
            },
            {
                // YYS 게시판 관리
                path: '/yys/bbs',
                name: 'BbsMgmt',
                component: () => import('../components/yys/BbsMgmt.vue')
            },
            {
                // YYS 프로그램 활동지
                path: '/yys/prginf',
                name: 'PrgInfMgmt',
                component: () => import('../components/yys/PrgInfMgmt.vue')
            },
            // {
            //     // YYS 1:1문의
            //     path: '/yys/qna',
            //     name: 'QnaMdl',
            //     component: () => import('../components/yys/BbsMgmt.vue')
            // },
            {
                // 시설권한관리
                path: '/yys/fclty',
                name: 'FcltyMgmt',
                component: () => import('../components/yys/FcltyMgmt.vue')
            },
            {
                // 시설서비스내역
                path: '/yys/pyminfo',
                name: 'PymInfoMgmt',
                component: () => import('../components/yys/PymInfoMgmt.vue')
            },            
            {
                // 시스템 오류내용
                path: '/yys/errlog',
                name: 'ErrLogMgmt',
                component: () => import('../components/yys/ErrLogMgmt.vue')
            },
            {
                // 도입 문의
                path: '/yys/intrd',
                name: 'IntrdMgmt',
                component: () => import('../components/yys/IntrdMgmt.vue')
            },
            {
                // 프로모션 접속정보
                path: '/yys/prmtaces',
                name: 'PrmtAces',
                component: () => import('../components/yys/PrmtAces.vue')
            },
        ],
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
