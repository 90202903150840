<template>
    <v-form v-model="pymEditForm">        
        <v-row class="pa-0" no-gutters>
            <v-col class="" cols="4">
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">기관번호</span>
            </v-col>
            <v-col class="" cols="4">
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">이용서비스</span>
            </v-col>
            <v-col  class="" cols="4">
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">결제내역</span>
            </v-col> 
        </v-row>
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
        <v-row class="pa-0" no-gutters>
            <v-col class="pl-2" cols="4">
                {{ pymHisInf.fcltyNum }}
            </v-col>
            <v-col class="pl-2" cols="4">
                {{ pymHisInf.srvcClcdNm }}
            </v-col>
            <v-col class="pl-2" cols="4">
                {{ pymHisInf.cntnt }}
            </v-col>
        </v-row>  
        <v-divider class="my-2" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="" cols="4">
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">결제상태&#40;결제일시&#41;</span>
            </v-col>
            <v-col class="" cols="4">
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">결제수단</span>
            </v-col>
            <v-col  class="" cols="4">
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">결제금액&#40;원&#41;</span>
            </v-col> 
        </v-row>
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
        <v-row class="pa-0" no-gutters>
            <v-col class="pl-2" cols="4">
                {{ pymHisInf.pymtSttNm }}&#40;{{ pymHisInf.pymttime }}&#41;
            </v-col>
            <v-col class="pl-2" cols="4">
                {{ pymHisInf.pymtMthdCdNm }}
            </v-col>
            <v-col class="pl-2" cols="4">
                {{ pymHisInf.pymtAmt.toLocaleString('ko-KR') }}
            </v-col>
        </v-row>
        <v-divider class="my-2" color="white"></v-divider>   
        <v-row class="pa-0" no-gutters>
            <v-col class="" cols="12">
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">비고</span>
            </v-col>
        </v-row>
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
        <v-row class="pa-0" no-gutters>
            <v-col class="pl-2" cols="12">
                <v-text-field
                    v-model="pymHisInf.rmrks"                                    
                    class="login-theme"
                    base-color="grey002" color="blue103"
                    density="compact" variant="outlined"
                    type="text"                                    
                    hide-details>
                </v-text-field>
            </v-col>
        </v-row>
        <v-divider class="my-2" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="ma-0 pa-0" cols="12">
                <v-data-table-server
                    class="tbl-theme rounded" density="compact" color="red"
                    height="300"    
                    v-model:items-per-page="pymParams.rnLmt"                    
                    :headers="tblUse.headers"
                    :items-length="tblUse.totalItems"
                    :items="tblUse.serverItems"
                    :loading="tblUse.loading"
                    item-value="prdCd"
                    @update:options="getYysUseList"
                    fixed-header expand-on-click>
                    <template v-slot:item="{ item }">
                        <tr>
                            <td class="txtAlgnMid">{{ item.prdCd }}</td>
                            <td class="txtAlgnMid">{{ item.prdTypCd }}</td>
                            <td class="txtAlgnMid">{{ item.bnfcrCnt }}</td>
                            <td class="txtAlgnMid">{{ item.pymtMnth }}</td>                            
                            <td class="txtAlgnMid">{{ item.txtCnt }}</td>
                            <td class="txtAlgnMid">{{ item.useAmt?.toLocaleString('ko-KR') }}</td>
                            <td class="txtAlgnMid">{{ item.vat?.toLocaleString('ko-KR') }}</td>
                            <td class="txtAlgnMid">
                                <span v-if="item.cnclYn == '1'">예</span>
                                <span v-else>아니오</span>
                            </td>
                            <td class="txtAlgnMid">
                                <v-text-field
                                    v-model="item.cnclMnth"                                    
                                    class="login-theme"
                                    base-color="grey002" color="blue103"
                                    density="compact" variant="outlined"
                                    type="number"                                    
                                    hide-details>
                                </v-text-field>
                            </td>
                            <td class="txtAlgnMid">
                                <v-text-field
                                    v-model="item.cnclTxtCnt"                                    
                                    class="login-theme"
                                    base-color="grey002" color="blue103"
                                    density="compact" variant="outlined"
                                    type="number"                                    
                                    hide-details>
                                </v-text-field>
                            </td>
                            <td class="txtAlgnMid">
                                <v-text-field
                                    v-model="item.cnclAmt"                                    
                                    class="login-theme"
                                    base-color="grey002" color="blue103"
                                    density="compact" variant="outlined"
                                    type="number"                                    
                                    hide-details>
                                </v-text-field>
                            </td>
                            <td class="txtAlgnMid">
                                <v-text-field
                                    v-model="item.cnclTax"                                    
                                    class="login-theme"
                                    base-color="grey002" color="blue103"
                                    density="compact" variant="outlined"
                                    type="number"                                    
                                    hide-details>
                                </v-text-field>
                            </td>
                            <td class="txtAlgnMid">    
                                <v-btn v-if="item.cnclYn == '2'"                        
                                    variant="plain"
                                    density="compact"
                                    @click="preCnclYysUse(item)"
                                    icon>
                                    <v-icon size="20" color="blue">mdi-content-save-edit-outline</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table-server>
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup>
    import { reactive, onMounted, watch, ref} from 'vue'
    import { lgnInf, notiInf, glbDlg} from "@/state";
    import { cmsApi } from '@/plugins/yysCmsApi'
    import { useRouter } from 'vue-router'

    const router = useRouter()
    const noti = notiInf()
    const lgn = lgnInf()
    const props = defineProps(['psdObj'])      
    const dlgInf = glbDlg() 

    
    const pymEditForm   = ref(false)

    let pymParams = reactive({
        rnStrt: 0,
        rnLmt: 10,  
        srchWd1: '',
        srchWd2: '',
        fcltyNum:'',
        srvcClcd:'',
        yysUseLstPk:'',
        yysPymHisPk:'',
    })

    let pymHisInf = reactive({
        yysPymHisPk     : 0,
        cntnt           : '',
        cntnt1          : '',
        cntnt2          : '',
        dpstrNm         : '',
        fcltyClnm       : '',
        fcltyNum        : '',
        mgtkey          : '',
        mjrPrdCd        : '',
        prcClcd         : '',
        pymtAmt         : '',
        pymtCnclCd      : '',
        pymtCnclDt      : '',
        pymtCnclDttm    : '',
        pymtCnclNm      : '',
        pymtDt          : '',
        pymtDttm        : '',
        pymtMthdCd      : '',
        pymtMthdCdNm    : '',
        pymtSttCd       : '',
        pymtSttNm       : '',
        pymttime        : '',
        rcid            : '',
        rmrks           : '',
        srvcClcd        : '',
        srvcClcdNm      : '',
        taxInvcCd       : '',
        taxInvcPk       : 0,
        trno            : '',
   })

   let tblUse = reactive({       
        loading: true,
        totalItems: 0,
        serverItems: [],
        headers:[
            { title: '상품코드', key: 'prdCd', sortable: false, align: 'center', width: '120' },
            { title: '상품종류코드', key: 'prdTypCd', sortable: false, align: 'center', width: '150' },
            { title: '수급자수', key: 'bnfcrCnt', sortable: false, align: 'center', minWidth: '100' },
            { title: '이용기간', key: 'pymtMnth', sortable: false, align: 'center', minWidth: '100' },            
            { title: '문자건수', key: 'pymtMnth', sortable: false, align: 'center', minWidth: '100' },
            { title: '이용요금', key: 'txtCnt', sortable: false, align: 'center', width: '150' },        
            { title: '부가세', key: 'vat', sortable: false, align: 'center', width: '150' },                        
            { title: '취소여부', key: 'cnclYn', sortable: false, align: 'center', width: '150' },
            { title: '취소기간', key: 'cnclMnth', sortable: false, align: 'center', width: '150' },
            { title: '취소 문자건수', key: 'cnclTxtCnt', sortable: false, align: 'center', width: '150' },
            { title: '취소금액', key: 'cnclAmt', sortable: false, align: 'center', width: '300' },
            { title: '취소 부가세', key: 'cnclTax', sortable: false, align: 'center', width: '300' },
            { title: '처리', key: 'actions', sortable: false, align: 'center', width: '150' },
        ],
    })
   

    onMounted(() => {
        setPymtHisInf(props.psdObj)
        let obj = {}
        obj.page = 1
        obj.itemsPerPage = pymParams.rnLmt
        getYysUseList(obj)
    })

    watch(() => props.psdObj, (newValue) => {

        if(!newValue){
            setPymtHisInf(newValue)        

            let obj = {}
            obj.page = 1
            obj.itemsPerPage = pymParams.rnLmt
            getYysUseList(obj)
        }
        
    })

    async function getYysUseList(obj) {
        tblUse.serverItems.splice(0)
        tblUse.loading = true
        pymParams.rnStrt = ((obj.page-1) * obj.itemsPerPage)

        await cmsApi.post('yyspym/selYysUseList', pymParams)
            .then((response) => {
                if(response.data.statusCode == '200'){   
                    console.log(response.data)
                    tblUse.loading = false 
                    tblUse.totalItems = response.data.result.rnTot

                    response.data.result.list.forEach(itm => {

                        tblUse.serverItems.push(itm)                        
                    });
                }
                else{
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    async function setCnclYysUse(obj) {
        obj.rmrks       =  pymHisInf.rmrks
        obj.fcltyNum    =  pymHisInf.fcltyNum
        await cmsApi.post('yyspym/updCnclYysUse', obj)
        .then((response) => {
            
                if(response.data.statusCode == '200'){
                    noti.$patch({ stt: true, ttl: 'Info', cntnt: response.data.message, type: 'info', tmOut: 2500 })
                    dlgInf.$patch({ isDlg: false, succActBtn: false })
                    let obj = {}
                    obj.page = 1
                    obj.itemsPerPage = pymParams.rnLmt
                    getYysUseList(obj)
                }
                else{
                    dlgInf.$patch({ succActBtn: false })
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    } 


    function setPymtHisInf(obj) {
        pymHisInf.yysPymHisPk       = obj.yysPymHisPk
        pymHisInf.cntnt             = obj.cntnt
        pymHisInf.cntnt1            = obj.cntnt1
        pymHisInf.cntnt2            = obj.cntnt2
        pymHisInf.dpstrNm           = obj.dpstrNm
        pymHisInf.fcltyClnm         = obj.fcltyClnm
        pymHisInf.fcltyNum          = obj.fcltyNum
        pymHisInf.mgtkey            = obj.mgtkey
        pymHisInf.mjrPrdCd          = obj.mjrPrdCd
        pymHisInf.prcClcd           = obj.prcClcd
        pymHisInf.pymtAmt           = obj.pymtAmt
        pymHisInf.pymtCnclCd        = obj.pymtCnclCd
        pymHisInf.pymtCnclDt        = obj.pymtCnclDt
        pymHisInf.pymtCnclDttm      = obj.pymtCnclDttm
        pymHisInf.pymtCnclNm        = obj.pymtCnclNm
        pymHisInf.pymtDt            = obj.pymtDt
        pymHisInf.pymtDttm          = obj.pymtDttm
        pymHisInf.pymtMthdCd        = obj.pymtMthdCd
        pymHisInf.pymtMthdCdNm      = obj.pymtMthdCdNm
        pymHisInf.pymtSttCd         = obj.pymtSttCd
        pymHisInf.pymtSttNm         = obj.pymtSttNm
        pymHisInf.pymttime          = obj.pymttime
        pymHisInf.rcid              = obj.rcid
        pymHisInf.rmrks             = obj.rmrks
        pymHisInf.srvcClcd          = obj.srvcClcd
        pymHisInf.srvcClcdNm        = obj.srvcClcdNm
        pymHisInf.taxInvcCd         = obj.taxInvcCd
        pymHisInf.taxInvcPk         = obj.taxInvcPk
        pymHisInf.trno              = obj.trno
        pymHisInf.yysUseLstPk       = obj.yysUseLstPk

        pymHisInf.cnclTxtCnt

        pymParams.fcltyNum          = obj.fcltyNum
        pymParams.srvcClcd          = obj.srvcClcd
        pymParams.yysUseLstPk       = obj.yysUseLstPk
        pymParams.yysPymHisPk       = obj.yysPymHisPk
    }

    function preCnclYysUse(obj){
        let pass = true
        let cntnt = '';

        obj.pymtCnclCd = '1'

        if(obj.useAmt < obj.cnclAmt){
            cntnt = '취소금액을 확인해주세요. (이용요금보다 클수 없습니다.)'
            pass = false
        } 

        if(obj.prdTypCd != '2' && obj.cnclAmt < 1){
            cntnt = '취소금액을 확인해주세요. (금액을 0이상 입력해주세요.)'
            pass = false
        }

        if(pass) {
            if(obj.vat < obj.cnclTax){
                cntnt = '취소부가세을 확인해주세요. (부가세보다 클수 없습니다.)'
                pass = false
            } 

            if(obj.prdTypCd != '2' && obj.cnclTax < 1){
                cntnt = '취소부가세을 확인해주세요. (금액을 0이상 입력해주세요.)'
                pass = false
            }
        }

        if(pass) {
            if(obj.srvcClcd == '2') {
                
                if(obj.txtCnt < obj.cnclTxtCnt || obj.cnclTxtCnt < 1){
                    cntnt = '취소문자건수를 확인해주세요. (문자건수보다 클수 없으며, 문자건수를 0이상 입력해주세요.)'
                    pass = false
                } else {
                    if(obj.txtCnt != obj.cnclTxtCnt) obj.pymtCnclCd = '2'
                }

                obj.cnclMnth = 0

            } else {
                
                if(obj.pymtMnth < obj.cnclMnth || obj.cnclMnth < 1){
                    cntnt = '취소이용기간를 확인해주세요. (이용기간보다 클수 없으며, 이용기간를 0이상 입력해주세요.)'
                    pass = false
                } else {
                    if(obj.pymtMnth != obj.cnclMnth) obj.pymtCnclCd = '2'
                }

                obj.cnclTxtCnt = 0

            }
        }
        
        
        if(pass){            
            setCnclYysUse(obj)
        } else {
            dlgInf.$patch({ succActBtn: false })
            noti.$patch({ stt: true, ttl: 'Error', cntnt: cntnt, type: 'error', tmOut: 3000 })
        }
        
    }

</script>