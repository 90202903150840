<template> 
<v-row class="pa-0" no-gutters>
        <v-col class="" cols="6">            
            <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">네이버아이디</span>
        </v-col>
        <v-col class="" cols="6">
            <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">화일위치URL</span>
        </v-col>
    </v-row> 
    <v-divider class="mt-1-qrtr-1" color="white"></v-divider> 
    <v-row class="pa-0" no-gutters>
        <v-col class="pl-2" cols="6">
            {{ prgInf.nvrId }}
        </v-col>
        <v-col class="pl-2" cols="6">        
            <a v-if="prgInf.fileLocUrl!='' && prgInf.fileLocUrl != null" :href="prgInf.fileLocUrl" target="_blank">바로가기</a>
        </v-col>
    </v-row>       
    <v-divider class="my-2" color="white"></v-divider>
    <v-row class="pa-0" no-gutters>
        <v-col class="" cols="6">            
            <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">프로그램</span>
        </v-col>
        <v-col class="" cols="6">
            <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">사용여부</span>
        </v-col>
    </v-row> 
    <v-divider class="mt-1-qrtr-1" color="white"></v-divider> 
    <v-row class="pa-0" no-gutters>
        <v-col class="pl-2" cols="6">
            {{ prgInf.prgNm }}
        </v-col>
        <v-col class="pl-2" cols="6">        
            <span v-if="prgInf.useYn == '1'">유</span>
            <span v-else-if="prgInf.useYn == '2'">무</span>
        </v-col>
    </v-row>  
    <v-divider class="my-2" color="white"></v-divider>
    <v-row class="pa-0" no-gutters>
        <v-col class="" cols="6">            
            <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">프로그램 유형</span>
        </v-col>
        <v-col class="" cols="6">
            <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">지표</span>
        </v-col>
    </v-row> 
    <v-divider class="mt-1-qrtr-1" color="white"></v-divider> 
    <v-row class="pa-0" no-gutters>
        <v-col class="pl-2" cols="6">            
            {{prgInf.prgTypNm}}
        </v-col>
        <v-col class="pl-2" cols="6">  
            {{ prgInf.idxNm }}
        </v-col>
    </v-row>  
    <v-divider class="my-2" color="white"></v-divider>
    <v-row class="pa-0" no-gutters>
        <v-col class="" cols="12">            
            <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">준비물</span>
        </v-col>
    </v-row> 
    <v-divider class="mt-1-qrtr-1" color="white"></v-divider> 
    <v-row class="pa-0" no-gutters>
        <v-col class="pl-2" cols="12">
            <div v-html="prgInf.mtrl"></div>
        </v-col>         
    </v-row>    
    <v-divider class="my-2" color="white"></v-divider>    
    <v-row class="pa-0" no-gutters>
        <v-col class="" cols="12">            
            <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">목표</span>
        </v-col>
    </v-row> 
    <v-divider class="mt-1-qrtr-1" color="white"></v-divider> 
    <v-row class="pa-0" no-gutters>
        <v-col class="pl-2" cols="12">
            <div v-html="prgInf.goal"></div>
        </v-col>         
    </v-row>    
    <v-divider class="my-2" color="white"></v-divider>    
    <v-row class="pa-0" no-gutters>
        <v-col class="" cols="12">            
            <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">프로그램 내용</span>
        </v-col>
    </v-row> 
    <v-divider class="mt-1-qrtr-1" color="white"></v-divider> 
    <v-row class="pa-0" no-gutters>
        <v-col class="pl-2" cols="12">
            <div v-html="prgInf.prgCntnt"></div>
        </v-col>         
    </v-row>    
    <v-divider class="my-2" color="white"></v-divider>    
    <v-row class="pa-0" no-gutters>
        <v-col class="" cols="12">            
            <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">기대효과</span>
        </v-col>
    </v-row> 
    <v-divider class="mt-1-qrtr-1" color="white"></v-divider> 
    <v-row class="pa-0" no-gutters>
        <v-col class="pl-2" cols="12">
            <div v-html="prgInf.expEfc"></div>
        </v-col>         
    </v-row>    
    <v-divider class="my-2" color="white"></v-divider>  
    <v-row class="pa-0" no-gutters>
        <v-col class="" cols="12">            
            <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">기능회복훈련&#40;급여제공기록지포함&#41;</span>
        </v-col>
    </v-row> 
    <v-divider class="mt-1-qrtr-1" color="white"></v-divider> 
    <v-row class="pa-0" no-gutters>
        <v-col class="pl-2" cols="12">
            <span class="px-1" v-for="(shtTyp, idx) in prgInf.shtTyps" :key="shtTyp">
                <b v-if="idx !=0">,</b>
                {{shtTyp}}
            </span>
        </v-col>         
    </v-row>    
    <v-divider class="my-2" color="white"></v-divider>  
    <div v-for="(itm, i) in prgInf.prgActshtlist" :key="i">
        <div v-if="itm.shtClcd != '3'">
            <v-row class="pa-0" no-gutters>
                <v-col class="" cols="12">            
                    <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">{{itm.shtClcdNm}}&#40;{{itm.fileLocNm}}&#41;</span>
                </v-col>
            </v-row> 
            <v-divider class="mt-1-qrtr-1" color="white"></v-divider> 
            <v-row class="pa-0" no-gutters>
                <v-col v-if="itm.fileLoc=='1'" class="pl-2" cols="12">
                    <v-img
                        referrerpolicy="no-referrer" 
                        :src="itm.nvrUrl" :aspect-ratio="itm.ratio" :max-width="itm.width">
                    </v-img>
                </v-col> 
                <v-col v-if="itm.fileLoc=='2'" class="pl-2" cols="12">              
                    <v-img
                        referrerpolicy="no-referrer"
                        :src="itm.src" :aspect-ratio="itm.ratio" :max-width="itm.width">
                    </v-img>
                </v-col> 
                <v-col v-if="itm.fileLoc=='3'" class="pl-2" cols="12">
                    <iframe width="1000" height="556" :src="itm.nvrUrl" frameborder="0"> </iframe>
                </v-col>
            </v-row>  
        </div>
        <div v-else>
            <v-row class="pa-0" no-gutters>                
                <v-col class="d-flex align-center" cols="12">
                    <v-icon class="mt-1 opct0-6" color="blue102" size="20">mdi-folder-check</v-icon>                                        
                    <a
                        v-if="itm.fileLoc=='1'" :href="itm.nvrUrl"
                        :download="itm.orgFileNm">
                        {{ itm.orgFileNm }}
                    </a>
                    <span v-else-if="itm.fileLoc=='2'"
                        class="d-inline-block crsShp-Pointer overflow-text-hidden px-2"
                        style="max-width: 300px; height: auto;"
                        @click="fileDown(itm)"
                        >
                        <span class="fnt0-8">{{ itm.orgFileNm }}</span>
                    </span>
                </v-col>
            </v-row> 
        </div>
        <v-divider class="my-2" color="white"></v-divider>    
    </div>
</template>
<script setup>
    import { reactive, onMounted, watch} from 'vue'
    import { lgnInf, notiInf, } from "@/state";
    import { useRouter } from 'vue-router'
    import { cmsApi, remoteURL } from '@/plugins/yysCmsApi'

    const noti          = notiInf()
    const lgn           = lgnInf()
    const router        = useRouter()
    const props         = defineProps(['psdObj'])

    let prgInf = reactive({
        loading         : false,
        prgInfPk        : 0,
        nvrId           : '',
        fileLocUrl      : '',
        prgTypMngPk 	: null,     //프로그램유형관리 고유번호
        prgNm 	        :'',    		//프로그램명
        plc 	        :'',    		//장소
        mc 	            :'',    			//진행자
        mcMmbrPk 	    :'',    	//진행자 구성원 고유번호
        mtrl 	        :'',    		//준비물
        goal 	        :'',    		//목표
        prgCntnt 	    :'',    	//프로그램내용
        expEfc 	        :'',    		//기대효과
        fncRcvCd 	    :[],    	//기능회복훈련코드
        fncRcvCds 	    :[],    	//기능회복훈련코드
        shtTyp 	        :[],		//활동지 유형구분(공통값분류코드 133참조)
        shtTyps	        :[],		//활동지 유형구분(공통값분류코드 133참조)
        shtMda 	        :[],		//활동지 미디어구분(공통값분류코드 134참조)
        shtMdas	        :[],		//활동지 미디어구분(공통값분류코드 134참조)
        shtSbjt 	    :[],		//활동지 활동지 주제구분(공통값분류코드 135참조)
        shtSbjts 	    :[],		//활동지 활동지 주제구분(공통값분류코드 135참조)
        useYn 	        :'',    		//사용여부
        idxNm           :'',
        prgTyplist      :[],
        vCd38          : [],
        vCd133          : [],
        vCd134          : [],
        vCd135          : [],
        prgActshtlist   : [],
        prgActshtHeaders    : [
            { title: '구분', key: 'shtClcd', sortable: false, align: 'center', width: '150' },
            { title: '화일위치', key: 'fileLoc', sortable: false, align: 'center', width: '150' },
            { title: '대표여부', key: 'rprsYn', sortable: false, align: 'center', width: '150' },
            { title: '내용', key: 'cntnt', sortable: false, align: 'center', width: '300' },            
            { title: '순서', key: 'ord', sortable: false, align: 'center', width: '100' },
            { title: '처리', key: 'action', sortable: false, align: 'center', width: '150' },
        ],
        shtClcdItem     : [
            { valcdnm: '활동예제', valcd: '1'},
            { valcdnm: '활동지', valcd: '2'},
            { valcdnm: '첨부화일', valcd: '3'}
        ],
        fileLocItem     : [
            { valcdnm: 'NAVER', valcd: '1'},
            { valcdnm: 'YYS', valcd: '2'},
            { valcdnm: 'YOUTUBE', valcd: '3'},
        ],
        rprsYnItem     : [
            { valcdnm: '예', valcd: '1'},
            { valcdnm: '아니오', valcd: '2'},
        ],
    })

    onMounted(() => {
        setPrgInf(props.psdObj)
    })

    watch(() => props.psdObj, (newValue) => {
        if(!newValue){
            setPrgInf(newValue)
        }
    })

    //프로그램 정보 상세 조회
    async function getPrgInf(obj) {
        prgInf.prgActshtlist.splice(0)
        await cmsApi.post('prgrm/selPrgInfInfo', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){                    
                    setData(response.data.result)
                }
                else{
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    function setPrgInf(obj){
        prgInf.prgTyplist =  obj.prgTyplist
        prgInf.vCd38 =  obj.vCd38
        prgInf.vCd133 =  obj.vCd133
        prgInf.vCd134 =  obj.vCd134
        prgInf.vCd135 =  obj.vCd135

        if(obj.prgInf != null){
            prgInf.prgInfPk         = obj.prgInf.prgInfPk
            getPrgInf(obj.prgInf)
        }

    }


    //프로그램 활동지 DB SETTING
    function setData(obj){

        if(obj.prgInf != null){
            
            prgInf.prgTypMngPk 	    = obj.prgInf.prgTypMngPk       //프로그램유형관리 고유번호
            prgInf.prgNm 	        = obj.prgInf.prgNm    		    //프로그램명
            prgInf.plc 	            = ''    		                //장소
            prgInf.mc 	            = ''    			            //진행자
            prgInf.mcMmbrPk 	    = ''    	                    //진행자 구성원 고유번호
            prgInf.mtrl 	        = obj.prgInf.mtrl?.replaceAll("\n", "<br />")    		    //준비물
            prgInf.goal 	        = obj.prgInf.goal?.replaceAll("\n", "<br />")   		    //목표
            prgInf.prgCntnt 	    = obj.prgInf.prgCntnt?.replaceAll("\n", "<br />")   	    //프로그램내용
            prgInf.expEfc 	        = obj.prgInf.expEfc?.replaceAll("\n", "<br />")    		    //기대효과
            prgInf.fncRcvCd 	    = obj.prgInf.fncRcvCd    	    //기능회복훈련코드
            prgInf.fncRcvCds 	    = []    	                    //기능회복훈련코드
            prgInf.shtTyp 	        = obj.prgInf.shtTyp             //활동지 유형구분(공통값분류코드 133참조)
            prgInf.shtTyps	        = []		                    //활동지 유형구분(공통값분류코드 133참조)
            prgInf.shtMda 	        = obj.prgInf.shtMda             //활동지 미디어구분(공통값분류코드 134참조)
            prgInf.shtMdas	        = []		                    //활동지 미디어구분(공통값분류코드 134참조)
            prgInf.shtSbjt 	        = obj.prgInf.shtSbjt		                    //활동지 활동지 주제구분(공통값분류코드 135참조)
            prgInf.shtSbjts 	    = []		                    //활동지 활동지 주제구분(공통값분류코드 135참조)
            prgInf.useYn 	        = obj.prgInf.useYn              //사용여부
            prgInf.idxNm            = obj.prgInf.idxNm
            prgInf.nvrId            = obj.prgInf.nvrId
            prgInf.fileLocUrl       = obj.prgInf.fileLocUrl

            prgInf.fncRcvCd?.forEach(el => {                            
                prgInf.vCd38?.forEach(cd => {
                    if(cd.valcd == el) prgInf.fncRcvCds.push(cd)
                });
            });

            prgInf.shtTyp?.forEach(el => {                            
                prgInf.vCd133.forEach(cd => {
                    if(cd.valcd == el) prgInf.shtTyps.push(cd.valcdnm)
                });
            });

            prgInf.shtMda?.forEach(el => {                            
                prgInf.vCd134.forEach(cd => {
                    if(cd.valcd == el) prgInf.shtMdas.push(cd)
                });
            });

            prgInf.shtSbjt?.forEach(el => {                            
                prgInf.vCd135.forEach(cd => {
                    if(cd.valcd == el) prgInf.shtSbjts.push(cd)
                });
            });
        }

        if(obj.actshtlist != null){
            setPrgActshtlist(obj.actshtlist)
        }

        setPrgTyp()
    }

    //프로그램 활동지 DB SETTING
    function setPrgActshtlist(list){
        list.forEach(el => {
            let obj = {                  
                shtClcd     : el.shtClcd,
                shtClcdNm   : '',
                ord         : el.ord,
                rprsYn      : el.rprsYn,
                rprsYnNm    : '',
                fileLoc     : el.fileLoc,
                fileLocNm   : '',
                fileNm      : el.fileNm,
                filePath    : el.filePath,
                orgFileNm   : el.orgFileNm,
                nvrUrl      : el.nvrUrl,
                prgActshtPk : el.prgActshtPk,
                ratio       :'1:1',
                style       :'',
                thmUrl      : el.thmUrl,
                width       : el.width,
                height      : el.height,
                videoId     :'',
            }

            if(el.width != '' &&  el.width != null && el.height != '' && el.height != null){
                if(Number(el.width) > Number(el.height)) {
                    let val = el.width/el.height
                    val = val.toFixed(1)
                    obj.ratio = val+'/'+1
                } else if(Number(el.width) < Number(el.height)) {
                    let val = el.height/el.width
                    val = val.toFixed(1)
                    obj.ratio = 1+'/'+val
                } 
            }

            prgInf.shtClcdItem.forEach(cd => {
                if(cd.valcd == obj.shtClcd) obj.shtClcdNm = cd.valcdnm
            });

            prgInf.fileLocItem.forEach(cd => {
                if(cd.valcd == obj.fileLoc) obj.fileLocNm = cd.valcdnm
            });

            prgInf.rprsYnItem.forEach(cd => {
                if(cd.valcd == obj.rprsYn) obj.rprsYnNm = cd.valcdnm
            });
            
            if(el.shtClcd != '3' && el.fileLoc == '2'){
                obj.src = remoteURL + 'main/imgView?fileNm='+el.fileNm+'&filePth='+el.filePath+'&fileKndCd=2'
            }

            if(el.shtClcd != '3' && el.fileLoc == '3'){
                obj.nvrUrl = 'https://www.youtube.com/embed/'+obj.nvrUrl+'?feature=oembed'
            }   

            prgInf.prgActshtlist.push(obj)
        });

    }

    // 파일 다운로드
    async function fileDown(itm){
        let obj = {
            fileNm      : itm.fileNm,
            filePth     : itm.filePath,
            orgFileNm   : itm.orgFileNm,
            atchdFilePk   : itm.prgActshtPk,
        }

        let uri = 'cmm/fileDownload'

        await cmsApi({
            method: 'post',
            url: uri,
            responseType: 'arraybuffer',
            data: obj
        }).then((response) => {
            createFile(response)
        })
        .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    function createFile(res){
        let contentDisposition = decodeURI(res.headers['content-disposition'])
       
        let link = document.createElement('a')
        let url = window.URL.createObjectURL(new Blob([res.data]))
        let fileName = 'unknown'

        if (contentDisposition) {
            const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'))
            if (fileNameMatch)
                [ , fileName ] = fileNameMatch.split('=')
        }
        link.href = url

        fileName = fileName.replaceAll('"', '')
        link.setAttribute('download', `${fileName}`)
        link.style.cssText = 'display:none'
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    function setPrgTyp(){
        
        if(prgInf.prgTypMngPk != null) {
            let idx = prgInf.prgTyplist.findIndex(v=> v.prgTypMngPk == prgInf.prgTypMngPk)
            
            prgInf.idxNm        = prgInf.prgTyplist[idx].idxNm
            prgInf.prgTypNm     = prgInf.prgTyplist[idx].prgTypNm

        }
        else prgInf.idxNm = ''
    }

</script>