<template>   
    <v-row class="pa-0" no-gutters>
        <v-col class="" cols="4">            
            <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">기관번호</span>
        </v-col>
        <v-col class="" cols="4">
            <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">이용서비스</span>
        </v-col>
        <v-col  class="" cols="4">
            <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">결제내역</span>
        </v-col> 
    </v-row>
    <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
    <v-row class="pa-0" no-gutters>
        <v-col class="pl-2" cols="4">
            {{ pymHisInf.fcltyNum }}
        </v-col>
        <v-col class="pl-2" cols="4">
            {{ pymHisInf.srvcClcdNm }}
        </v-col>
        <v-col class="pl-2" cols="4">
            {{ pymHisInf.cntnt }}
        </v-col>
    </v-row>  
    <v-divider class="my-2" color="white"></v-divider>
    <v-row class="pa-0" no-gutters>
        <v-col class="" cols="4">
            <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">결제상태&#40;결제일시&#41;</span>
        </v-col>
        <v-col class="" cols="4">
            <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">결제수단</span>
        </v-col>
        <v-col  class="" cols="4">
            <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">결제금액&#40;원&#41;</span>
        </v-col> 
    </v-row>
    <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
    <v-row class="pa-0" no-gutters>
        <v-col class="pl-2" cols="4">
            {{ pymHisInf.pymtSttNm }}&#40;{{ pymHisInf.pymttime }}&#41;
        </v-col>
        <v-col class="pl-2" cols="4">
            {{ pymHisInf.pymtMthdCdNm }}
        </v-col>
        <v-col class="pl-2" cols="4">
            {{ pymHisInf.pymtAmt.toLocaleString('ko-KR') }}
        </v-col>
    </v-row>
    <v-divider class="my-2" color="white"></v-divider>
    <v-expansion-panels>
        <v-expansion-panel v-if="pymHisInf.bnkTrnsf.show">
            <v-expansion-panel-title>
                무통장 입금 정보
            </v-expansion-panel-title>
            <v-expansion-panel-text>
                <v-row class="pa-0" no-gutters>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">입금자명</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">환불받을계좌</span>
                    </v-col>
                    <v-col  class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">연락처</span>
                    </v-col> 
                </v-row>
                <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
                <v-row class="pa-0" no-gutters>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.bnkTrnsf.dpstrNm }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.bnkTrnsf.dpstrAcct }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.bnkTrnsf.dpstrTel1+'-'+pymHisInf.bnkTrnsf.dpstrTel2+'-'+pymHisInf.bnkTrnsf.dpstrTel3 }}
                    </v-col>
                </v-row>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel v-if="pymHisInf.taxInvc.length > 0">
            <v-expansion-panel-title>
                세금계산서/현금영수증 신청 정보
            </v-expansion-panel-title>
            <v-expansion-panel-text>
                <v-row class="pa-0" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col class="" cols="1">
                        <v-btn v-if="pymHisInf.pymtSttCd == '5'"
                            class="grdt-btn-blue001 text-white"
                            width="100%" height="40" variant="elevated"     
                            @click="tblPymTaxInvcAct"                       
                            >
                            <v-icon class="mr-1">mdi-plus-circle</v-icon>
                            <span class="fnt0-7 font-weight-black">Add</span>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="my-2" color="white"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="" cols="12">
                        <div v-if="taxInvcInf.isDataTbl">
                            <v-data-table-virtual
                                class="tbl-theme-virtual rounded" density="compact" color="red"
                                height="300"                                                           
                                :headers="pymHisInf.taxInvcHeaders"
                                :items="pymHisInf.taxInvc"
                                :loading="pymHisInf.loading"
                                item-value="taxInvcCd"
                                fixed-header expand-on-click>
                                <template v-slot:item="{ item }">
                                    <tr>
                                        <td class="txtAlgnMid"  @click="tblPymTaxInvcViewAct(item)">
                                            <span v-if="item.taxInvcCd == '1'">세금계산서</span>
                                            <span v-else-if="item.taxInvcCd == '2'">현금영수증</span>
                                        </td>                            
                                        <td class="txtAlgnMid"  @click="tblPymTaxInvcViewAct(item)">
                                            <span v-if="item.prcClcd == '1'">요청</span>
                                            <span v-else-if="item.prcClcd == '2'">진행중</span>
                                            <span v-else-if="item.prcClcd == '3'">발급정상</span>
                                            <span v-else-if="item.prcClcd == '4'">발급오류</span>
                                            <span v-else-if="item.prcClcd == '5'">발급취소</span>
                                            <span v-else-if="item.prcClcd == '6'">발급취소(팝빌연동불가)</span>
                                        </td>                                           
                                        <td class="txtAlgnMid"  @click="tblPymTaxInvcViewAct(item)">    
                                            <span v-if="item.cashRcptCd == '1'">개인소독 공제용</span>
                                            <span v-else-if="item.cashRcptCd == '2'">사업자 지출 증빙용</span>
                                        </td>
                                        <td class="txtAlgnMid"  @click="tblPymTaxInvcViewAct(item)">
                                            {{ item.ceo }}
                                        </td>
                                        <td class="txtAlgnMid"  @click="tblPymTaxInvcViewAct(item)">
                                            {{ item.bsnsRgst }}
                                        </td>
                                        <td class="txtAlgnMid"  @click="tblPymTaxInvcViewAct(item)">
                                            {{ item.mgtkey }}
                                        </td>
                                        <td class="txtAlgnMid">      
                                            <div v-if="item.prcClcd == '1'">
                                                <v-tooltip v-if="pymHisInf.pymtMthdCd != '2' && item.taxInvcCd != null && (pymHisInf.pymtSttCd == '2' || pymHisInf.pymtSttCd == '5')" location="bottom">
                                                    <template v-slot:activator="{ props  }">
                                                        <v-btn        
                                                            v-bind="props"                                            
                                                            variant="plain"
                                                            class="mr-2"
                                                            style="margin:0px !important;padding:0px !important"                                                
                                                            icon   
                                                            @click="preCachBill(item)" >
                                                            <v-icon size="20" color="blue">mdi-invoice-text-arrow-right-outline</v-icon>
                                                        </v-btn>
                                                    </template>                                
                                                    <span v-if="item.taxInvcCd =='1'">전자세금계산서 발행</span>
                                                    <span v-else-if="item.taxInvcCd =='2'">현금영수증 발행</span>
                                                </v-tooltip>
                                            </div>
                                            <div v-else-if="item.prcClcd=='3'">                                        
                                                <v-tooltip v-if="pymHisInf.pymtMthdCd != '2'" location="bottom">
                                                    <template v-slot:activator="{ props  }">
                                                        <v-btn        
                                                            v-bind="props"                                            
                                                            variant="plain"
                                                            class="mr-2"
                                                            style="margin:0px !important;padding:0px !important"                                                
                                                            icon   
                                                            @click="selPymPopUpUrl(item)" >
                                                            <v-icon size="20" color="blue">mdi-printer-outline</v-icon>
                                                        </v-btn>
                                                    </template>                                
                                                    <span v-if="item.taxInvcCd =='1'">전자세금계산서 보기</span>
                                                    <span v-else-if="item.taxInvcCd =='2'">현금영수증 보기</span>
                                                </v-tooltip>
    
                                                <v-tooltip v-if="pymHisInf.pymtMthdCd != '2' && pymHisInf.pymtSttCd == '5'" location="bottom">
                                                    <template v-slot:activator="{ props  }">
                                                        <v-btn        
                                                            v-bind="props"                                            
                                                            variant="plain"
                                                            class="mr-2"
                                                            style="margin:0px !important;padding:0px !important"  
                                                            @click="setTaxInvcPrcClcd(item)"                                              
                                                            icon>
                                                            <v-icon size="20" color="red">mdi-invoice-remove-outline</v-icon>
                                                        </v-btn>
                                                    </template>                                
                                                    <span v-if="item.taxInvcCd =='1'">전자세금계산서 취소</span>
                                                    <span v-else-if="item.taxInvcCd =='2'">현금영수증 취소</span>
                                                </v-tooltip>
                                            </div>    
                                            <div v-else-if="item.prcClcd=='5'">                                        
                                                <v-tooltip location="bottom">
                                                    <template v-slot:activator="{ props  }">
                                                        <v-btn        
                                                            v-bind="props"                                            
                                                            variant="plain"
                                                            class="mr-2"
                                                            style="margin:0px !important;padding:0px !important"                                                
                                                            icon   
                                                            @click="selPymPopUpUrl(item)" >
                                                            <v-icon size="20" color="blue">mdi-printer-outline</v-icon>
                                                        </v-btn>
                                                    </template>                                
                                                    <span v-if="item.taxInvcCd =='1'">전자세금계산서 보기</span>
                                                    <span v-else-if="item.taxInvcCd =='2'">현금영수증 보기</span>
                                                </v-tooltip>
                                            </div>                     
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table-virtual>
                        </div>
                        <div v-else>
                            <v-card
                                rounded="lg">
                                <v-row class="bg-grey align-center py-4 px-5" no-gutters>
                                    <span class="py-2 fnt1-2 font-weight-bold">세금계산서/현금영수증 상세</span>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        class="" @click="taxInvcInf.isDataTbl = true"
                                        variant="plain" size="30" icon slim>
                                        <v-icon color="white" size="26">mdi-window-close</v-icon>
                                    </v-btn>
                                </v-row>
                                <div class="overflow-y-auto px-8 py-5">
                                    <v-row class="pa-0" no-gutters>
                                        <v-col class="" cols="4">
                                            <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">결재금액</span>
                                        </v-col>
                                        <v-col class="" cols="4">
                                            <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">공급가액</span>
                                        </v-col>
                                        <v-col  class="" cols="4">
                                            <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">부가세</span>
                                        </v-col> 
                                    </v-row>
                                    <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
                                    <v-row class="pa-0" no-gutters>
                                        <v-col class="pl-2" cols="4">
                                            {{taxInvcInf.obj.totalamount?.toLocaleString('ko-KR')}}
                                        </v-col>
                                        <v-col class="pl-2" cols="4">
                                            {{taxInvcInf.obj.supplycosttotal?.toLocaleString('ko-KR')}}
                                        </v-col>
                                        <v-col class="pl-2" cols="4">
                                            {{taxInvcInf.obj.taxtotal?.toLocaleString('ko-KR')}}
                                        </v-col>
                                    </v-row>
                                    <v-divider class="my-2" color="white"></v-divider>
                                    <v-row class="pa-0" no-gutters>
                                        <v-col class="" cols="4">
                                            <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">발급종류</span>
                                        </v-col>
                                        <v-col class="" cols="4">
                                            <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">결과</span>
                                        </v-col>
                                        <v-col  class="" cols="4">
                                            <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">용도</span>
                                        </v-col> 
                                    </v-row>
                                    <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
                                    <v-row class="pa-0" no-gutters>
                                        <v-col class="pl-2" cols="4">
                                            <span v-if="taxInvcInf.obj.taxInvcCd == '1'">세금계산서</span>
                                            <span v-else-if="taxInvcInf.obj.taxInvcCd == '2'">현금영수증</span>
                                        </v-col>
                                        <v-col class="pl-2" cols="4">
                                            <span v-if="taxInvcInf.obj.prcClcd == '1'">요청</span>
                                            <span v-else-if="taxInvcInf.obj.prcClcd == '2'">진행중</span>
                                            <span v-else-if="taxInvcInf.obj.prcClcd == '3'">발급정상</span>
                                            <span v-else-if="taxInvcInf.obj.prcClcd == '4'">발급오류</span>
                                            <span v-else-if="taxInvcInf.obj.prcClcd == '5'">발급취소</span>
                                            <span v-else-if="taxInvcInf.obj.prcClcd == '6'">발급취소(팝빌연동불가)</span>
                                            <span v-if="taxInvcInf.obj.prcClcd == '4' || taxInvcInf.obj.prcClcd == '6'" class="">오류메세지 : {{taxInvcInf.obj.errMsg}}</span>
                                        </v-col>
                                        <v-col class="pl-2" cols="4">
                                            <span v-if="taxInvcInf.obj.cashRcptCd == '1'">개인소독 공제용</span>
                                            <span v-else-if="taxInvcInf.obj.cashRcptCd == '2'">사업자 지출 증빙용</span>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="my-2" color="white"></v-divider>
                                    <div v-if="taxInvcInf.obj.taxInvcCd == '1'">
                                        <v-row class="pa-0" no-gutters>
                                            <v-col class="" cols="4">
                                                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">상호</span>
                                            </v-col>
                                            <v-col class="" cols="4">
                                                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">업태</span>
                                            </v-col>
                                            <v-col class="" cols="4">
                                                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">업종</span>
                                            </v-col>
                                        </v-row>
                                        <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
                                        <v-row class="pa-0" no-gutters>
                                            <v-col class="pl-2" cols="4">
                                                {{ taxInvcInf.obj.cmpnNm }}
                                            </v-col>
                                            <v-col class="pl-2" cols="4">
                                                {{ taxInvcInf.obj.bsnsCndtn }}
                                            </v-col>
                                            <v-col class="pl-2" cols="4">
                                                {{ taxInvcInf.obj.bsnsItm }}
                                            </v-col>
                                        </v-row>
                                        <v-divider class="my-2" color="white"></v-divider>
                                        <v-row class="pa-0" no-gutters>
                                            <v-col class="" cols="4">
                                                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">사업자 번호</span>
                                            </v-col>
                                            <v-col class="" cols="4">
                                                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">대표자명</span>
                                            </v-col>
                                            <v-col class="" cols="4">
                                                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">담당자명</span>
                                            </v-col>
                                        </v-row>
                                        <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
                                        <v-row class="pa-0" no-gutters>
                                            <v-col class="pl-2" cols="4">
                                                {{ taxInvcInf.obj.bsnsRgst }}
                                            </v-col>
                                            <v-col class="pl-2" cols="4">
                                                {{ taxInvcInf.obj.ceo }}
                                            </v-col>
                                            <v-col class="pl-2" cols="4">
                                                {{ taxInvcInf.obj.mngr }}
                                            </v-col>
                                        </v-row>
                                        <v-divider class="my-2" color="white"></v-divider>
                                        <v-row class="pa-0" no-gutters>
                                            <v-col class="" cols="4">
                                                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">전화번호</span>
                                            </v-col>
                                            <v-col class="" cols="4">
                                                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">이메일</span>
                                            </v-col>
                                            <v-col class="" cols="4">
                                                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">사업장 주소</span>
                                            </v-col>
                                        </v-row>
                                        <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
                                        <v-row class="pa-0" no-gutters>
                                            <v-col class="pl-2" cols="4">
                                                {{ taxInvcInf.obj.tel1+'-'+taxInvcInf.obj.tel2+'-'+taxInvcInf.obj.tel3 }}
                                            </v-col>
                                            <v-col class="pl-2" cols="4">
                                                {{ taxInvcInf.obj.emailId+'@'+taxInvcInf.obj.emailDmn }}
                                            </v-col>
                                            <v-col class="pl-2" cols="4">
                                                {{ '['+taxInvcInf.obj.zip+']'+taxInvcInf.obj.addr+' '+taxInvcInf.obj.addrDtl }}
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div v-else-if="taxInvcInf.obj.taxInvcCd == '2'">
                                        <v-row class="pa-0" no-gutters>
                                            <v-col class="" cols="4">
                                                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">신청자명</span>
                                            </v-col>
                                            <v-col class="" cols="4">
                                                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">휴대폰 번호/사업자 번호</span>
                                            </v-col>
                                        </v-row>
                                        <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
                                        <v-row class="pa-0" no-gutters>
                                            <v-col class="pl-2" cols="4">
                                                {{ taxInvcInf.obj.ceo }}
                                            </v-col>
                                            <v-col class="pl-2" cols="4">
                                                {{ taxInvcInf.obj.bsnsRgst }}
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <v-divider class="my-2" color="white"></v-divider>
                                    <v-row class="pa-0" no-gutters>
                                        <v-col class="d-flex justify-center align-center" cols="12">
                                            <div v-if="taxInvcInf.obj.prcClcd == '1'">
                                                <v-btn                                                
                                                    class="grdt-btn-blue001 text-white mx-1"
                                                    height="34" variant="elevated"
                                                    @click="preCachBill(taxInvcInf.obj)">
                                                    <v-icon size="20" color="white">mdi-invoice-text-arrow-right-outline</v-icon>
                                                    <span class="ml-2 fnt0-8">발행</span>
                                                </v-btn>
                                            </div>
                                            <div v-else-if="taxInvcInf.obj.prcClcd=='3'"> 
                                                <v-btn  v-if="pymHisInf.pymtMthdCd != '2'"                                               
                                                    class="grdt-btn-blue001 text-white mx-1"
                                                    height="34" variant="elevated"
                                                    @click="selPymPopUpUrl(taxInvcInf.obj)">
                                                    <v-icon size="20" color="white">mdi-printer-outline</v-icon>
                                                    <span class="ml-2 fnt0-8">출력</span>
                                                </v-btn>     
                                                
                                                <v-btn  v-if="pymHisInf.pymtMthdCd != '2' && pymHisInf.pymtSttCd == '5'"                                              
                                                    class="grdt-btn-blue001 text-white mx-1"
                                                    height="34" variant="elevated"
                                                    @click="setTaxInvcPrcClcd(taxInvcInf.obj)">
                                                    <v-icon size="20" color="white">mdi-invoice-remove-outline</v-icon>
                                                    <span class="ml-2 fnt0-8">취소</span>
                                                </v-btn>                                               
                                            </div>
                                            <div v-else-if="taxInvcInf.obj.prcClcd=='5'">
                                                <v-btn                                             
                                                    class="grdt-btn-blue001 text-white mx-1"
                                                    height="34" variant="elevated"
                                                    @click="selPymPopUpUrl(taxInvcInf.obj)">
                                                    <v-icon size="20" color="white">mdi-printer-outline</v-icon>
                                                    <span class="ml-2 fnt0-8">출력</span>
                                                </v-btn> 
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card>
                        </div>
                    </v-col>
                </v-row>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel v-if="pymHisInf.ksnetPaySnd.show">
            <v-expansion-panel-title>
                KSNET 송신 정보
            </v-expansion-panel-title>
            <v-expansion-panel-text>                
                <v-row class="pa-0" no-gutters>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">상점아이디</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">주문번호</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">상품명</span>
                    </v-col>
                </v-row>
                <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
                <v-row class="pa-0" no-gutters>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.sndstoreid }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.sndordernumber }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.sndgoodname }}
                    </v-col>
                </v-row>
                <v-divider class="my-2" color="white"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">금액</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">주문자명</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">이메일</span>
                    </v-col>
                </v-row>
                <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
                <v-row class="pa-0" no-gutters>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.sndamount?.toLocaleString('ko-KR') }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.sndordername }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.sndemail }}
                    </v-col>
                </v-row>
                <v-divider class="my-2" color="white"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">이동전화</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">용역제공기간</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">할부개월수</span>
                    </v-col>
                </v-row>
                <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
                <v-row class="pa-0" no-gutters>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.sndmobile }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.sndserviceperiod }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.sndinstallmenttype }}
                    </v-col>
                </v-row>
                <v-divider class="my-2" color="white"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">가맹점부담_무이자할부</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">현금영수증 발급 여부</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">에스크로 사용 여부</span>
                    </v-col>
                </v-row>
                <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
                <v-row class="pa-0" no-gutters>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.sndinteresttype }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.sndcashreceipt }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.sndescrow }}
                    </v-col>
                </v-row>
                <v-divider class="my-2" color="white"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">해당은행 미표시</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">가상계좌마감일자</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">이용서비스</span>
                    </v-col>
                </v-row>
                <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
                <v-row class="pa-0" no-gutters>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.sndvirdenybank }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.sndvirexpdt + ' '+ pymHisInf.ksnetPaySnd.sndvirexptm}}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.srvcClcd }}
                    </v-col>
                </v-row>
                <v-divider class="my-2" color="white"></v-divider>
                <v-row class="pa-0" no-gutters>                    
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">결제수단코드</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">대표상품코드</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">수급자수</span>
                    </v-col>
                </v-row>
                <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
                <v-row class="pa-0" no-gutters>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.pymtMthdCd }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.mjrPrdCd }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.bnfcrCnt }}
                    </v-col>
                </v-row>
                <v-divider class="my-2" color="white"></v-divider>
                <v-row class="pa-0" no-gutters>                    
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">이용기간</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">문자건수</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">세금계산서 발급코드</span>
                    </v-col>
                </v-row>
                <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
                <v-row class="pa-0" no-gutters>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.pymtMnth }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.textCnt }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.taxInvcCd }}
                    </v-col>
                </v-row>
                <v-divider class="my-2" color="white"></v-divider>
                <v-row class="pa-0" no-gutters>                    
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">요양시스이용 최종정보 고유번호</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">결제 Key값</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">결제요청유형</span>
                    </v-col>
                </v-row>
                <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
                <v-row class="pa-0" no-gutters>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.yysUseLstPk }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.rcid }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.rctype }}
                    </v-col>
                </v-row>
                <v-divider class="my-2" color="white"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="" cols="8">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">검증 Hash값</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">결제상태&#40;코드&#41;</span>
                    </v-col>
                </v-row>
                <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
                <v-row class="pa-0" no-gutters>
                    <v-col class="pl-2" cols="8">
                        {{ pymHisInf.ksnetPaySnd.rhash }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPaySnd.statustxt +'('+ pymHisInf.ksnetPaySnd.status +')'}}
                    </v-col>
                </v-row>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel v-if="pymHisInf.ksnetPayRcv.show">
            <v-expansion-panel-title>
                KSNET 수신 정보
            </v-expansion-panel-title>
            <v-expansion-panel-text>
                <v-row class="pa-0" no-gutters>                    
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">KSNET 결제응답정보 고유번호</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">이용요금 결제내역 고유번호</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">상점아이디</span>
                    </v-col>
                </v-row>
                <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
                <v-row class="pa-0" no-gutters>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPayRcv.ksnetPayRcvPk }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPayRcv.yysPymHisPk }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPayRcv.storied }}
                    </v-col>
                </v-row>
                <v-divider class="my-2" color="white"></v-divider>
                <v-row class="pa-0" no-gutters>                    
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">주문자명</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">상품명</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">성공여부</span>
                    </v-col>
                </v-row>
                <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
                <v-row class="pa-0" no-gutters>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPayRcv.username }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPayRcv.goodname }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPayRcv.authyn }}
                    </v-col>
                </v-row>
                <v-divider class="my-2" color="white"></v-divider>
                <v-row class="pa-0" no-gutters>                    
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">거래번호</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">거래일자</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">금액</span>
                    </v-col>
                </v-row>
                <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
                <v-row class="pa-0" no-gutters>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPayRcv.trno }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPayRcv.trddt+' '+pymHisInf.ksnetPayRcv.trdtm }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPayRcv.amt?.toLocaleString('ko-KR') }}
                    </v-col>
                </v-row>
                <v-divider class="my-2" color="white"></v-divider>
                <v-row class="pa-0" no-gutters>                    
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">승인번호</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">메시지1</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">메시지2</span>
                    </v-col>
                </v-row>
                <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
                <v-row class="pa-0" no-gutters>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPayRcv.authno }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPayRcv.msg1 }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPayRcv.msg2 }}
                    </v-col>
                </v-row>
                <v-divider class="my-2" color="white"></v-divider>
                <v-row class="pa-0" no-gutters>                    
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">주문번호</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">발급사코드&#40;신용카드&#41;</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">매입사코드&#40;신용카드&#41;</span>
                    </v-col>
                </v-row>
                <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
                <v-row class="pa-0" no-gutters>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPayRcv.ordno }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPayRcv.isscd }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPayRcv.aqucd }}
                    </v-col>
                </v-row>
                <v-divider class="my-2" color="white"></v-divider>
                <v-row class="pa-0" no-gutters>                    
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">승인구분</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">응답코드</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">간편결제 구분코드</span>
                    </v-col>
                </v-row>
                <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
                <v-row class="pa-0" no-gutters>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPayRcv.result }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPayRcv.resultcd }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPayRcv.certitype }}
                    </v-col>
                </v-row>
                <v-divider class="my-2" color="white"></v-divider>
                <v-row class="pa-0" no-gutters>                    
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">할부개월 수</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">카드번호</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">현금영수증 발급 정상 여부</span>
                    </v-col>
                </v-row>
                <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
                <v-row class="pa-0" no-gutters>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPayRcv.halbu }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPayRcv.cardno }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPayRcv.cbauthyn }}
                    </v-col>
                </v-row>
                <v-divider class="my-2" color="white"></v-divider>
                <v-row class="pa-0" no-gutters>                    
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">현금영수증 거래번호</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">현금영수증 승인번호</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">응답메세지</span>
                    </v-col>
                </v-row>
                <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
                <v-row class="pa-0" no-gutters>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPayRcv.cbtrno }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPayRcv.cbauthno }}
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        {{ pymHisInf.ksnetPayRcv.cbmsg1 }}
                    </v-col>
                </v-row>
                <v-divider class="my-2" color="white"></v-divider>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>

    <v-dialog
        v-model="taxInvcDlgInf.isMdl"
        width="800">
        <PymTaxInvcMdl :psdObj="taxInvcDlgInf.obj" @onClose="isClose"></PymTaxInvcMdl>
    </v-dialog>
</template>
<script setup>
    import PymTaxInvcMdl from '@/components/yys/PymTaxInvcMdl.vue'               

    import { reactive, onMounted, watch} from 'vue'
    import { lgnInf, notiInf,  glbDlg } from "@/state";
    import { cmsApi } from '@/plugins/yysCmsApi'
    import { useRouter } from 'vue-router'

    const router = useRouter()
    const noti = notiInf()
    const lgn = lgnInf()
    const dlgInf = glbDlg()
    const props = defineProps(['psdObj'])

    let taxInvcDlgInf = reactive({
        obj: null,
        isMdl: false,
    })

    let taxInvcInf = reactive({
        obj: null,
        isDataTbl: true,
    })

    let pymHisInf = reactive({
        yysPymHisPk     : 0,
        cntnt           : '',
        cntnt1          : '',
        cntnt2          : '',
        dpstrNm         : '',
        fcltyClnm       : '',
        fcltyNum        : '',
        mgtkey          : '',
        mjrPrdCd        : '',
        prcClcd         : '',
        pymtAmt         : '',
        pymtCnclCd      : '',
        pymtCnclDt      : '',
        pymtCnclDttm    : '',
        pymtCnclNm      : '',
        pymtDt          : '',
        pymtDttm        : '',
        pymtMthdCd      : '',
        pymtMthdCdNm    : '',
        pymtSttCd       : '',
        pymtSttNm       : '',
        pymttime        : '',
        rcid            : '',
        rmrks           : '',
        srvcClcd        : '',
        srvcClcdNm      : '',
        taxInvcCd       : '',
        taxInvcPk       : 0,
        trno            : '',
        yysUseLstPk     : 0,
        cnclAmt         : 0,
        cnclTax         : 0,
        cnclTxtCnt      : 0,
        cnclMnth        : 0,
        bnkTrnsf        : {
            show        : true,
            pymtDt      : '',   //결제일자
            pymtAmt	    : 0,    //결제금액
            dpstrNm	    : '',   //입금자명
            dpstrAcct	: '',   //입금계좌
            dpstrTel1	: '',   //입금자 연락처1
            dpstrTel2	: '',   //입금자 연락처2
            dpstrTel3	: '',   //입금자 연락처3
        },
        taxInvcHeaders:[
            { title: '발급종류', key: 'taxInvcCd', sortable: false, align: 'center', width: '100' },
            { title: '결과', key: 'prcClcd', sortable: false, align: 'center', width: '150' },
            { title: '용도', key: 'cashRcptCd', sortable: false, align: 'center', width: '150' },
            { title: '수신자', key: 'cmpnNm', sortable: false, align: 'center', width: '150' },
            { title: '수신자번호', key: 'bsnsRgst', sortable: false, align: 'center', width: '150' },
            { title: '문서번호', key: 'mgtKey', sortable: false, align: 'center', width: '150' },            
            { title: '처리', key: 'actions', sortable: false, align: 'center', width: '150' },
        ],
        taxInvc        : [{         
            taxInvcCd	       : '', //세금계산서 발급코드(1:세금계산서, 2:현금영수증)
            prcClcd		       : '', //처리구분코드(1:요청,2:진행중,3:발급정상,4:발급오류)
            errMsg		       : '', //오류메세지
            cashRcptCd	       : '', //현금영수증 사용용도(1:개인소독 공제용, 2:사업자 지출 증빙용 )
            cmpnNm		       : '', //상호
            bsnsRgst	       : '', //사업자번호(개인소득공제용인 경우 휴대폰 번호)
            ceo			       : '', //대표자명
            bsnsCndtn	       : '', //업태
            bsnsItm		       : '', //업종
            tel1		       : '', //전화번호1
            tel2		       : '', //전화번호2
            tel3		       : '', //전화번호3
            zip			       : '', //사업장주소_우편번호
            addr		       : '', //사업장주소
            addrDtl		       : '', //사업장주소_상세주소
            mngr		       : '', //담당자명
            emailId		       : '', //이메일 ID
            emailDmn	       : '', //이메일 도메인
            mgtkey    	       : '', //파트너가 할당한 문서번호
            writedate	       : '', //작성일자
            supplycosttotal    : '',     //공급가액 합계
            taxtotal           : '', //세액 합계
            totalamount        : '', //합계금액
            ntsconfirmnum      : '', //국세청승인번호				
            pymtSttCd	       : '', //결제상태코드(공통항목값분류코드 131)	 
            cntnt1		       : '', //비고
            crtDt              : ''
        }],
        ksnetPaySnd        : {
            show                : true,
            sndpaymethod       	: '',       //결제수단
            sndstoreid         	: '',     //상점아이디
            sndordernumber     	: '',     //주문번호
            sndgoodname        	: '',     //상품명
            sndamount          	: '',     //금액
            sndordername       	: '',     //주문자명
            sndemail           	: '',     //이메일 
            sndmobile          	: '',     //이동전화
            sndserviceperiod   	: '',     //용역제공기간
            sndinstallmenttype 	: '',     //할부개월수(선택할 수 있는 할부 개월 수)
            sndinteresttype    	: '',     //가맹점부담_무이자할부(가맹점에서 무이자 할부 수수료를 전액 부담하는 경우 설정)
            sndcashreceipt     	: '',     //계좌이체 시 현금영수증 발급 여부
            sndescrow          	: '',     //에스크로 사용 여부
            sndvirdenybank     	: '',     //해당은행 미표시
            sndvirexpdt        	: '',     //가상계좌마감일자
            sndvirexptm        	: '',     //가상계좌마감시간
            srvcClcd           	: '',    	//이용서비스 구분코드(1:요양시스obj.2:문자충전obj.3:홈페이지)
            pymtMthdCd         	: '',   	//결제수단코드(1:무통장 입금obj. 2:신용카드obj. 3:실시간계좌이체obj.  4:예수금)
            mjrPrdCd           	: '',   	//대표상품코드
            bnfcrCnt           	: '',    	//수급자수
            pymtMnth           	: '',    	//이용기간(개월)
            textCnt            	: '',    	//문자건수
            pymtAmt            	: '',    	//결제금액
            taxInvcCd          	: '',   	//세금계산서 발급코드(1:세금계산서obj. 2:현금영수증)
            yysUseLstPk        	: 0,  	//요양시스이용 최종정보 고유번호
            rcid               	: '',     //결제 Key값
            rctype             	: '',     //결제요청유형
            rhash              	: '',     //검증 Hash값
            status             	: '',      //결제상태 코드(공통항목값분류코드 130참조)
            statustxt          	: ''      //결제상태 코드(공통항목값분류코드 130참조)
        },
        ksnetPayRcv        : {
            show          : true,
            ksnetPayRcvPk : 0,		// KSNET 결제응답정보 고유번호
            yysPymHisPk   : 0,	      // 이용요금 결제내역 고유번호
            storied       : '',	      // 상점아이디
            username      : '',	      // 주문자명
            goodname      : '',	      // 상품명
            authyn        : '',	      // 성공여부(영어 대문자 O-성공, X-거절)
            trno          : '',	      // 거래번호
            trddt         : '',	      // 거래일자(YYYYMMDD)
            trdtm         : '',	      // 거래시간(HHMMTT)
            amt           : '',	      // 금액
            authno        : '',	      // 승인번호((신용카드:결제성공시), 에러 코드(신용카드:승인거절시), 은행코드(가상계좌, 계좌이체))
            msg1          : '',	      // 메시지1
            msg2          : '',	      // 메시지2
            ordno         : '',	      // 주문번호(영어 대문자 O-성공, X-거절)
            isscd         : '',	      // 발급사코드(신용카드)
            aqucd         : '',	      // 매입사코드(신용카드)
            result        : '',	      // 승인구분
            resultcd      : '',	      // 응답코드
            certitype     : '',	      // 간편결제 구분코드
            halbu         : '',	      // 할부개월 수
            cardno        : '',	      // 카드번호
            cbauthyn      : '',	      // 현금영수증 발급 정상 여부(O: 현금영수증 발급 정상, X : 실패 (sndCashReceipt=1 일때 응답))
            cbtrno        : '',	      // 현금영수증 거래번호 (sndCashReceipt=1 일때 응답)
            cbauthno      : '',	      // 현금영수증 승인번호 (sndCashReceipt=1 일때 응답)
            cbmsg1        : '',	      // 응답메세지1 (sndCashReceipt=1 일때 응답)
            cbmsg2        : '',	       // 응답메세지2(sndCashReceipt=1 일때 응답) 
        },
        vCd130            : [],
        vCd131            : []
    })

    onMounted(() => {
        setPymtHisInf(props.psdObj)
        selYysPymHisDetail(props.psdObj)
    })

    watch(() => props.psdObj, (newValue) => {
        setPymtHisInf(newValue)
        selYysPymHisDetail(newValue)
    })

    //주문내역 상세
    async function selYysPymHisDetail(obj) {
        pymHisInf.taxInvc.splice(0)
        pymHisInf.loading = true

        await cmsApi.post('yyspym/selYysPymHisDetail', obj)
        .then((response) => {
                pymHisInf.loading = false
                if(response.data.statusCode == '200'){

                    if(response.data.result.codes != null){
                        pymHisInf.vCd130 = response.data.result.codes.vCd130
                        pymHisInf.vCd131 = response.data.result.codes.vCd131
                    }

                    if(response.data.result.bnkTrnsf != null){
                        setPymBnkTrnsf(response.data.result.bnkTrnsf)
                    } else {
                        pymHisInf.bnkTrnsf.show = false
                    }

                    if(response.data.result.taxInvc != null){
                        setPymTaxInvc(response.data.result.taxInvc)
                    } 

                    if(response.data.result.ksnetPaySnd != null){
                        setPymPaySnd(response.data.result.ksnetPaySnd)
                    } else {
                        pymHisInf.ksnetPaySnd.show = false
                    }

                    if(response.data.result.ksnetPayRcv != null){
                        setPymPayRcv(response.data.result.ksnetPayRcv)
                    } else {
                        pymHisInf.ksnetPayRcv.show = false
                    }
                }
                else{
                    dlgInf.$patch({ succActBtn: false })
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
        } 
    
    //현금영수증발행
    async function setPymCashBill(obj) {
        obj.fcltyNum = pymHisInf.fcltyNum
        obj.cntnt    = pymHisInf.cntnt
        obj.cntnt1   = pymHisInf.cntnt1
        obj.cntnt2   = pymHisInf.cntnt2
        await cmsApi.post('yyspym/insPymCashBill', obj)
            .then((response) => {
            
            if(response.data.statusCode == '200'){
                noti.$patch({ stt: true, ttl: 'Info', cntnt: response.data.message, type: 'info', tmOut: 2500 })                          
                selYysPymHisDetail(pymHisInf)
            }
            else{
                dlgInf.$patch({ succActBtn: false })
                if(response.data.statusCode == '610'){
                    lgn.$patch({
                        account: '',
                        name: '',
                        level: '',
                        token: '',
                        key: '',
                    })
                    noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })    
                    router.push({ name: 'LoginComp' })
                }
                else
                    noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
            }
        })
        .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    //계산서 발행 상태 변경
    async function setTaxInvcPrcClcd(obj) {
        obj.fcltyNum = pymHisInf.fcltyNum
        obj.prcClcd = '5' //발급취소
        await cmsApi.post('yyspym/updTaxInvcPrcClcd', obj)
            .then((response) => {
            
            if(response.data.statusCode == '200'){
                noti.$patch({ stt: true, ttl: 'Info', cntnt: response.data.message, type: 'info', tmOut: 2500 })     
                taxInvcInf.isDataTbl = true           
                selYysPymHisDetail(pymHisInf)
            }
            else{
                dlgInf.$patch({ succActBtn: false })
                if(response.data.statusCode == '610'){
                    lgn.$patch({
                        account: '',
                        name: '',
                        level: '',
                        token: '',
                        key: '',
                    })
                    noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })    
                    router.push({ name: 'LoginComp' })
                }
                else
                    noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
            }
        })
        .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    //영수증 url 조회
    async function selPymPopUpUrl(obj) {
        obj.fcltyNum = pymHisInf.fcltyNum
        await cmsApi.post('yyspym/selCashBillPopUpUrl', obj)
        .then((response) => {
            
            if(response.data.statusCode == '200'){
                onPopup(response.data.result)
            }   
            else{
                dlgInf.$patch({ succActBtn: false })
                if(response.data.statusCode == '610'){
                    lgn.$patch({
                        account: '',
                        name: '',
                        level: '',
                        token: '',
                        key: '',
                    })
                    noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })    
                    router.push({ name: 'LoginComp' })
                }
                else
                    noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
            }
        })
        .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    } 

    function setPymtHisInf(obj) {
        pymHisInf.yysPymHisPk       = obj.yysPymHisPk
        pymHisInf.cntnt             = obj.cntnt
        pymHisInf.cntnt1            = obj.cntnt1
        pymHisInf.cntnt2            = obj.cntnt2
        pymHisInf.dpstrNm           = obj.dpstrNm
        pymHisInf.fcltyClnm         = obj.fcltyClnm
        pymHisInf.fcltyNum          = obj.fcltyNum
        pymHisInf.mgtkey            = obj.mgtkey
        pymHisInf.mjrPrdCd          = obj.mjrPrdCd
        pymHisInf.prcClcd           = obj.prcClcd
        pymHisInf.pymtAmt           = obj.pymtAmt
        pymHisInf.pymtCnclCd        = obj.pymtCnclCd
        pymHisInf.pymtCnclDt        = obj.pymtCnclDt
        pymHisInf.pymtCnclDttm      = obj.pymtCnclDttm
        pymHisInf.pymtCnclNm        = obj.pymtCnclNm
        pymHisInf.pymtDt            = obj.pymtDt
        pymHisInf.pymtDttm          = obj.pymtDttm
        pymHisInf.pymtMthdCd        = obj.pymtMthdCd
        pymHisInf.pymtMthdCdNm      = obj.pymtMthdCdNm
        pymHisInf.pymtSttCd         = obj.pymtSttCd
        pymHisInf.pymtSttNm         = obj.pymtSttNm
        pymHisInf.pymttime          = obj.pymttime
        pymHisInf.rcid              = obj.rcid
        pymHisInf.rmrks             = obj.rmrks
        pymHisInf.srvcClcd          = obj.srvcClcd
        pymHisInf.srvcClcdNm        = obj.srvcClcdNm
        pymHisInf.taxInvcCd         = obj.taxInvcCd
        pymHisInf.taxInvcPk         = obj.taxInvcPk
        pymHisInf.trno              = obj.trno
        pymHisInf.yysUseLstPk       = obj.yysUseLstPk

        pymHisInf.cnclAmt        = obj.cnclAmt
        pymHisInf.cnclTax        = obj.cnclTax
        pymHisInf.cnclTxtCnt     = obj.cnclTxtCnt
        pymHisInf.cnclMnth       = obj.cnclMnth
    }

    function setPymBnkTrnsf(obj) {
        pymHisInf.bnkTrnsf.pymtDt       = obj.pymtDt   //결제일자
        pymHisInf.bnkTrnsf.pymtAmt	    = obj.pymtAmt    //결제금액
        pymHisInf.bnkTrnsf.dpstrNm	    = obj.dpstrNm   //입금자명
        pymHisInf.bnkTrnsf.dpstrAcct	= obj.dpstrAcct   //입금계좌
        pymHisInf.bnkTrnsf.dpstrTel1	= obj.dpstrTel1   //입금자 연락처1
        pymHisInf.bnkTrnsf.dpstrTel2	= obj.dpstrTel2   //입금자 연락처2
        pymHisInf.bnkTrnsf.dpstrTel3	= obj.dpstrTel3   //입금자 연락처3
    }

    function setPymTaxInvc(list){ 
        list.forEach(obj => {                       
            pymHisInf.taxInvc.push(obj)
        });       
    }

    function setPymPaySnd(obj){
        pymHisInf.ksnetPaySnd.sndpaymethod = obj.sndpaymethod              //결제수단
        pymHisInf.ksnetPaySnd.sndstoreid = obj.sndstoreid              //상점아이디
        pymHisInf.ksnetPaySnd.sndordernumber = obj.sndordernumber          //주문번호
        pymHisInf.ksnetPaySnd.sndgoodname = obj.sndgoodname             //상품명
        pymHisInf.ksnetPaySnd.sndamount = obj.sndamount               //금액
        pymHisInf.ksnetPaySnd.sndordername = obj.sndordername            //주문자명
        pymHisInf.ksnetPaySnd.sndemail = obj.sndemail                //이메일 
        pymHisInf.ksnetPaySnd.sndmobile = obj.sndmobile               //이동전화
        pymHisInf.ksnetPaySnd.sndserviceperiod = obj.sndserviceperiod        //용역제공기간
        pymHisInf.ksnetPaySnd.sndinstallmenttype = obj.sndinstallmenttype      //할부개월수(선택할 수 있는 할부 개월 수)
        pymHisInf.ksnetPaySnd.sndinteresttype = obj.sndinteresttype         //가맹점부담_무이자할부(가맹점에서 무이자 할부 수수료를 전액 부담하는 경우 설정)
        pymHisInf.ksnetPaySnd.sndcashreceipt = obj.sndcashreceipt          //계좌이체 시 현금영수증 발급 여부
        pymHisInf.ksnetPaySnd.sndescrow = obj.sndescrow               //에스크로 사용 여부
        pymHisInf.ksnetPaySnd.sndvirdenybank = obj.sndvirdenybank          //해당은행 미표시
        pymHisInf.ksnetPaySnd.sndvirexpdt = obj.sndvirexpdt             //가상계좌마감일자
        pymHisInf.ksnetPaySnd.sndvirexptm = obj.sndvirexptm             //가상계좌마감시간
        pymHisInf.ksnetPaySnd.srvcClcd = obj.srvcClcd               	//이용서비스 구분코드(1:요양시스obj.2:문자충전obj.3:홈페이지)
        pymHisInf.ksnetPaySnd.pymtMthdCd = obj.pymtMthdCd            	//결제수단코드(1:무통장 입금obj. 2:신용카드obj. 3:실시간계좌이체obj.  4:예수금)
        pymHisInf.ksnetPaySnd.mjrPrdCd = obj.mjrPrdCd              	//대표상품코드
        pymHisInf.ksnetPaySnd.bnfcrCnt = obj.bnfcrCnt               	//수급자수
        pymHisInf.ksnetPaySnd.pymtMnth = obj.pymtMnth               	//이용기간(개월)
        pymHisInf.ksnetPaySnd.textCnt = obj.textCnt                	//문자건수
        pymHisInf.ksnetPaySnd.pymtAmt = obj.pymtAmt                	//결제금액
        pymHisInf.ksnetPaySnd.taxInvcCd = obj.taxInvcCd             	//세금계산서 발급코드(1:세금계산서obj. 2:현금영수증)
        pymHisInf.ksnetPaySnd.yysUseLstPk = obj.yysUseLstPk          	//요양시스이용 최종정보 고유번호
        pymHisInf.ksnetPaySnd.rcid = obj.rcid                    //결제 Key값
        pymHisInf.ksnetPaySnd.rctype = obj.rctype                  //결제요청유형
        pymHisInf.ksnetPaySnd.rhash = obj.rhash                   //검증 Hash값
        pymHisInf.ksnetPaySnd.status = obj.status                  //결제상태 코드(공통항목값분류코드 130참조)

        pymHisInf.vCd130.forEach(cd => {
            if(obj.status  == cd.valcd) pymHisInf.ksnetPaySnd.statustxt = cd.valcdnm
        });
    }

    function setPymPayRcv(obj){
        pymHisInf.ksnetPayRcv.ksnetPayRcvPk = obj.ksnetPayRcvPk		// KSNET 결제응답정보 고유번호
        pymHisInf.ksnetPayRcv.yysPymHisPk = obj.yysPymHisPk         // 이용요금 결제내역 고유번호
        pymHisInf.ksnetPayRcv.storied = obj.storied             // 상점아이디
        pymHisInf.ksnetPayRcv.username = obj.username            // 주문자명
        pymHisInf.ksnetPayRcv.goodname = obj.goodname            // 상품명
        pymHisInf.ksnetPayRcv.authyn = obj.authyn              // 성공여부(영어 대문자 O-성공, X-거절)
        pymHisInf.ksnetPayRcv.trno = obj.trno                // 거래번호
        pymHisInf.ksnetPayRcv.trddt = obj.trddt               // 거래일자(YYYYMMDD)
        pymHisInf.ksnetPayRcv.trdtm = obj.trdtm               // 거래시간(HHMMTT)
        pymHisInf.ksnetPayRcv.amt = obj.amt                 // 금액
        pymHisInf.ksnetPayRcv.authno = obj.authno              // 승인번호((신용카드:결제성공시), 에러 코드(신용카드:승인거절시), 은행코드(가상계좌, 계좌이체))
        pymHisInf.ksnetPayRcv.msg1 = obj.msg1                // 메시지1
        pymHisInf.ksnetPayRcv.msg2 = obj.msg2                // 메시지2
        pymHisInf.ksnetPayRcv.ordno = obj.ordno               // 주문번호(영어 대문자 O-성공, X-거절)
        pymHisInf.ksnetPayRcv.isscd = obj.isscd               // 발급사코드(신용카드)
        pymHisInf.ksnetPayRcv.aqucd = obj.aqucd               // 매입사코드(신용카드)
        pymHisInf.ksnetPayRcv.result = obj.result              // 승인구분
        pymHisInf.ksnetPayRcv.resultcd = obj.resultcd            // 응답코드
        pymHisInf.ksnetPayRcv.certitype = obj.certitype           // 간편결제 구분코드
        pymHisInf.ksnetPayRcv.halbu = obj.halbu               // 할부개월 수
        pymHisInf.ksnetPayRcv.cardno = obj.cardno              // 카드번호
        pymHisInf.ksnetPayRcv.cbauthyn = obj.cbauthyn            // 현금영수증 발급 정상 여부(O: 현금영수증 발급 정상, X : 실패 (sndCashReceipt=1 일때 응답))
        pymHisInf.ksnetPayRcv.cbtrno = obj.cbtrno              // 현금영수증 거래번호 (sndCashReceipt=1 일때 응답)
        pymHisInf.ksnetPayRcv.cbauthno = obj.cbauthno            // 현금영수증 승인번호 (sndCashReceipt=1 일때 응답)
        pymHisInf.ksnetPayRcv.cbmsg1 = obj.cbmsg1              // 응답메세지1 (sndCashReceipt=1 일때 응답)
        pymHisInf.ksnetPayRcv.cbmsg2 = obj.cbmsg2               // 응답메세지2(sndCashReceipt=1 일때 응답) 
    }

    function preCachBill(itm){
        if(itm.taxInvcCd =='1' || itm.taxInvcCd =='2') setPymCashBill(itm)        
    }

    function onPopup(obj){        
        let receiptWin = obj.url
        window.open(receiptWin , '' , 'width=810, height=720, menubar=no, toolbar=no, station=no resizable=no, scrollbars=no')
    }


    function tblPymTaxInvcAct(){
        taxInvcDlgInf.isMdl = true
        taxInvcDlgInf.obj = pymHisInf.taxInvc[0]
        taxInvcDlgInf.obj.fcltyNum = pymHisInf.fcltyNum
        taxInvcDlgInf.obj.yysPymHisPk = pymHisInf.yysPymHisPk
        taxInvcDlgInf.obj.cnclAmt = pymHisInf.cnclAmt       
        taxInvcDlgInf.obj.cnclTax = pymHisInf.cnclTax      
        taxInvcDlgInf.obj.cntnt   = pymHisInf.cntnt      
        taxInvcDlgInf.obj.cntnt1  = pymHisInf.cntnt1       
        taxInvcDlgInf.obj.cntnt2  = pymHisInf.cntnt2       
    }

    function tblPymTaxInvcViewAct(obj){
        taxInvcInf.obj          = obj
        taxInvcInf.isDataTbl    = false
    }
    
    function isClose(){
        taxInvcDlgInf.isMdl=false
        selYysPymHisDetail(pymHisInf)
    }
</script>
