<template>
    <v-form v-model="prgInfForm">
        <v-row class="pa-0" no-gutters>
            <v-col class="" cols="6">            
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">프로그램</span>
            </v-col>
            <v-col class="" cols="6">
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">사용여부</span>
            </v-col>
        </v-row> 
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider> 
        <v-row class="pa-0" no-gutters>
            <v-col class="pl-2" cols="6">
                <v-text-field
                    v-model="prgInf.prgNm"
                    class="login-theme"
                    base-color="grey002" color="blue103"
                    density="compact" variant="outlined" 
                    :rules="[rules.getRequired]"                           
                    hide-details>
                </v-text-field>
            </v-col>
            <v-col class="pl-2" cols="6">                
                <v-radio-group
                    v-model="prgInf.useYn"
                    :rules="[rules.getRequired]"
                    inline>
                    <v-radio class="pa-0 ma-0 mr-2 fnt0-8" label="유" value="1"></v-radio>
                    <v-radio class="pa-0 ma-0 fnt0-8" label="무" value="2"></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>  
        <v-divider class="my-2" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="" cols="6">            
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">프로그램 유형</span>
            </v-col>
            <v-col class="" cols="6">
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">지표</span>
            </v-col>
        </v-row> 
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider> 
        <v-row class="pa-0" no-gutters>
            <v-col class="pl-2" cols="6">
                <v-select
                    v-model="prgInf.prgTypMngPk"
                    class="login-Theme"
                    base-color="grey002" bg-color="white" color="blue103"
                    density="compact" variant="outlined"
                    item-title="prgTypNm" item-value="prgTypMngPk"
                    :items="prgInf.prgTyplist"
                    @update:modelValue="setPrgTyp"   
                    :rules="[rules.getRequired]"
                    hide-details>               
                </v-select>
            </v-col>
            <v-col class="pl-2" cols="6">                
                <v-text-field
                    v-model="prgInf.idxNm"
                    class="login-theme"
                    base-color="grey002" color="blue103"
                    density="compact" variant="outlined"    
                    readonly                        
                    hide-details>
                </v-text-field>
            </v-col>
        </v-row>  
        <v-divider class="my-2" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="" cols="6">            
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">준비물</span>
            </v-col>
            <v-col class="" cols="6">
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">목표</span>
            </v-col>
        </v-row> 
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider> 
        <v-row class="pa-0" no-gutters>
            <v-col class="pl-2" cols="6">
                <v-textarea
                    v-model="prgInf.mtrl"
                    class="vTxtArea-theme" color="blue103" base-color="grey002"
                    variant="outlined" density="compact" rows="5"
                    :rules="[rules.getRequired]"
                    no-resize hide-details>
                </v-textarea>
            </v-col>
            <v-col class="pl-2" cols="6">                
                <v-textarea
                    v-model="prgInf.goal"
                    class="vTxtArea-theme" color="blue103" base-color="grey002"
                    variant="outlined" density="compact" rows="5"
                    :rules="[rules.getRequired]"
                    no-resize hide-details>
                </v-textarea>
            </v-col>
        </v-row>  
        <v-divider class="my-2" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="" cols="12">            
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">프로그램 내용&#40;진행과정&#41;</span>
            </v-col>
        </v-row> 
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider> 
        <v-row class="pa-0" no-gutters>
            <v-col class="pl-2" cols="12">
                <v-textarea
                    v-model="prgInf.prgCntnt"
                    class="vTxtArea-theme" color="blue103" base-color="grey002"
                    variant="outlined" density="compact" rows="14"
                    :rules="[rules.getRequired]"
                    no-resize hide-details>
                </v-textarea>
            </v-col>
        </v-row>  
        <v-divider class="my-2" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="" cols="12">            
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">기대효과</span>
            </v-col>
        </v-row> 
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider> 
        <v-row class="pa-0" no-gutters>
            <v-col class="pl-2" cols="12">
                <v-textarea
                    v-model="prgInf.expEfc"
                    class="vTxtArea-theme" color="blue103" base-color="grey002"
                    variant="outlined" density="compact" rows="4"
                    :rules="[rules.getRequired]"
                    no-resize hide-details>
                </v-textarea>
            </v-col>
        </v-row>  
        <v-divider class="my-2" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="" cols="6">            
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">기능회복훈련&#40;급여제공기록지포함&#41;</span>
            </v-col>
            <v-col class="" cols="6">            
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">유형</span>
            </v-col>
        </v-row> 
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider> 
        <v-row class="pa-0" no-gutters>
            <v-col class="pl-2" cols="6">
                <v-combobox
                    v-model="prgInf.fncRcvCds"
                    clearable
                    chips
                    multiple
                    :items="prgInf.vCd38"
                    item-title="valcdnm" item-value="valcd"
                    variant="outlined"
                    ></v-combobox>
            </v-col>
            <v-col class="pl-2" cols="6">                
                <v-combobox
                    v-model="prgInf.shtTyps"
                    clearable
                    chips
                    multiple
                    :items="prgInf.vCd133"
                    item-title="valcdnm" item-value="valcd"
                    variant="outlined"
                    ></v-combobox>
            </v-col>
        </v-row>  
        <v-divider class="my-2" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="" cols="6">            
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">미디어</span>
            </v-col>
            <v-col class="" cols="6">            
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">주제</span>
            </v-col>
        </v-row> 
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider> 
        <v-row class="pa-0" no-gutters>
            <v-col class="pl-2" cols="6">
                <v-combobox
                    v-model="prgInf.shtMdas"
                    clearable
                    chips
                    multiple                    
                    :items="prgInf.vCd134"
                    item-title="valcdnm" item-value="valcd"
                    variant="outlined"
                    ></v-combobox>
            </v-col>
            <v-col class="pl-2" cols="6">
                <v-combobox
                    v-model="prgInf.shtSbjts"
                    clearable
                    chips
                    multiple
                    :items="prgInf.vCd135"
                    item-title="valcdnm" item-value="valcd"
                    variant="outlined"
                    ></v-combobox>
            </v-col>
        </v-row>  
        <v-divider class="my-2" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="" cols="4">            
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">활동지 파일</span>
            </v-col>
            <v-spacer></v-spacer>                 
            <v-col cols="1">
                <v-btn
                    class="grdt-btn-blue001 text-white"
                    @click="addPrgActsht"
                    width="100%" height="32" variant="elevated">
                    <v-icon class="mr-1">mdi-plus-circle</v-icon>
                    <span class="fnt0-7 font-weight-black">Add</span>
                </v-btn>
            </v-col>
        </v-row> 
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider> 
        <v-row class="pa-0" no-gutters>
            <v-col class="pl-2" cols="12">
                <v-data-table-virtual
                    class="tbl-theme-virtual rounded" density="compact" color="red"
                    height="300"                                                           
                    :headers="prgInf.prgActshtHeaders"
                    :items="prgInf.prgActshtlist"
                    :loading="prgInf.loading"
                    item-value="taxInvcCd"
                    fixed-header expand-on-click>
                    <template v-slot:item="{ item }">
                        <tr>
                            <td class="txtAlgnMid">                  
                                <v-select
                                    v-model="item.shtClcd"
                                    class="login-Theme"
                                    base-color="grey002" bg-color="white" color="blue103"
                                    density="compact" variant="outlined"
                                    item-title="valcdnm" item-value="valcd"
                                    :items="prgInf.shtClcdItem"
                                    :disabled="item.prgActshtPk > 0 && item.fileLoc == '2'"
                                    hide-details>               
                                </v-select>
                            </td>
                            <td class="txtAlgnMid">
                                <v-select
                                    v-model="item.fileLoc"
                                    class="login-Theme"
                                    base-color="grey002" bg-color="white" color="blue103"
                                    density="compact" variant="outlined"
                                    item-title="valcdnm" item-value="valcd"
                                    :items="prgInf.fileLocItem"
                                    :disabled="item.prgActshtPk > 0 && item.fileLoc == '2'"
                                    hide-details>               
                                </v-select>
                            </td>
                            <td class="txtAlgnMid">
                                <v-select
                                    v-model="item.rprsYn"
                                    class="login-Theme"
                                    base-color="grey002" bg-color="white" color="blue103"
                                    density="compact" variant="outlined"
                                    item-title="valcdnm" item-value="valcd"
                                    :items="prgInf.rprsYnItem"
                                    :disabled="item.prgActshtPk > 0 && item.fileLoc == '2'"
                                    hide-details>               
                                </v-select>
                            </td>
                            <td class="txtAlgnMid">
                                <div v-if="item.fileLoc == '1'" class="py-2">
                                    <div class="pb-2">
                                        <v-text-field v-if="item.shtClcd == '3'"
                                            v-model="item.orgFileNm"
                                            class="login-theme"
                                            base-color="grey002" color="blue103"
                                            density="compact" variant="outlined"   
                                            placeholder="파일명"                        
                                            hide-details>
                                        </v-text-field> 
                                    </div>
                                    <div class="pb-2 d-flex justify-start align-center">
                                        <span style="width:150px;">                                            
                                            <v-text-field v-if="item.shtClcd != '3'"
                                                v-model="item.width"
                                                class="login-theme"
                                                base-color="grey002" color="blue103"
                                                density="compact" variant="outlined"   
                                                placeholder="이미지 폭"                        
                                                hide-details>
                                            </v-text-field> 
                                        </span>
                                        <span class="pl-1" style="width:150px;"> 
                                            <v-text-field v-if="item.shtClcd != '3'"
                                                v-model="item.height"
                                                class="login-theme"
                                                base-color="grey002" color="blue103"
                                                density="compact" variant="outlined"   
                                                placeholder="이미지 높이"                        
                                                hide-details>
                                            </v-text-field> 
                                        </span>                                        
                                    </div>
                                    <div>
                                        <v-text-field 
                                            v-model="item.nvrUrl"
                                            class="login-theme"
                                            base-color="grey002" color="blue103"
                                            density="compact" variant="outlined"   
                                            placeholder="NAVER LINK"                        
                                            hide-details>
                                        </v-text-field>                                                                       
                                    </div>
                                </div>
                                <div v-if="item.fileLoc == '2'" >
                                    <div v-if="item.prgActshtPk > 0 && item.fileLoc == '2'">{{item.fileNm}}</div>                                    
                                    <div class="pt-5" v-else>
                                        <v-file-input
                                            v-model="item.upFiles" :show-size="1000"
                                            class="v-file-outlined-Theme" color="blue103"
                                            placeholder="Upload your documents"
                                            prepend-inner-icon="mdi-upload-box-outline" prepend-icon=""
                                            density="compact" variant="outlined"
                                            @update:modelValue="updFile(item)"
                                            counter>
                                            <template v-slot:selection="{ fileNames }">
                                                <template v-for="(fileName, index) in fileNames" :key="fileName">
                                                    <v-chip
                                                        v-if="index < 1"
                                                        class="" color="indigo-accent-4" size="small"
                                                        label>
                                                        {{ fileName }}
                                                    </v-chip>
                                    
                                                    <span
                                                        v-else-if="index === 1"
                                                        class="text-overline text-grey-darken-3 mx-2">
                                                        + {{ item.upFiles.length - 1 }} File(s)
                                                    </span>
                                                </template>
                                            </template>
                                        </v-file-input>
                                    </div>
                                </div>
                                <div v-if="item.fileLoc == '3'" class="py-2">
                                    <div>
                                        <v-text-field 
                                            v-model="item.thmUrl"
                                            class="login-theme"
                                            base-color="grey002" color="blue103"
                                            density="compact" variant="outlined"   
                                            placeholder="스샷 LINK"                        
                                            hide-details>
                                        </v-text-field>                                                                       
                                    </div>
                                    <div class="mt-3">
                                        <v-text-field 
                                            v-model="item.nvrUrl"
                                            class="login-theme"
                                            base-color="grey002" color="blue103"
                                            density="compact" variant="outlined"   
                                            placeholder="유튜브 공유 LINK"                        
                                            hide-details>
                                        </v-text-field>                                                                       
                                    </div>
                                </div>
                            </td>
                            <td class="txtAlgnMid">
                                <v-text-field
                                    v-model="item.ord"
                                    type="number"
                                    class="login-theme"
                                    base-color="grey002" color="blue103"
                                    density="compact" variant="outlined"       
                                    :disabled="item.prgActshtPk > 0 && item.fileLoc == '2'"                    
                                    hide-details>
                                </v-text-field>
                            </td>
                            <td class="txtAlgnMid">
                                <v-tooltip>
                                    <template v-slot:activator="{ props  }">
                                        <v-btn        
                                            v-bind="props"                                            
                                            variant="plain"
                                            class="mr-2"
                                            style="margin:0px !important;padding:0px !important"  
                                            @click="rmActsht(item)"                                              
                                            icon>
                                            <v-icon size="20" color="red">mdi-trash-can-outline</v-icon>
                                        </v-btn>
                                    </template>                                
                                    <span>프로그램 활동지 삭제</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </template>
                </v-data-table-virtual>    
            </v-col>
        </v-row>  
    </v-form>
</template>
<script setup>
    import { reactive, onMounted, watch, ref} from 'vue'
    import { lgnInf, notiInf, glbDlg, glbRules} from "@/state";
    import { useRouter } from 'vue-router'
    import { cmsApi } from '@/plugins/yysCmsApi'

    const dlgState      = glbDlg()
    const prgInfForm    = ref(false)
    const noti          = notiInf()
    const lgn           = lgnInf()
    const router        = useRouter()
    const rules         = glbRules()
    const props         = defineProps(['psdObj'])

    let prgInf = reactive({
        loading         : false,
        prgInfPk        : 0,
        prgTypMngPk 	: null,     //프로그램유형관리 고유번호
        prgNm 	        :'',    		//프로그램명
        plc 	        :'',    		//장소
        mc 	            :'',    			//진행자
        mcMmbrPk 	    :'',    	//진행자 구성원 고유번호
        mtrl 	        :'',    		//준비물
        goal 	        :'',    		//목표
        prgCntnt 	    :'',    	//프로그램내용
        expEfc 	        :'',    		//기대효과
        fncRcvCd 	    :[],    	//기능회복훈련코드
        fncRcvCds 	    :[],    	//기능회복훈련코드
        shtTyp 	        :[],		//활동지 유형구분(공통값분류코드 133참조)
        shtTyps	        :[],		//활동지 유형구분(공통값분류코드 133참조)
        shtMda 	        :[],		//활동지 미디어구분(공통값분류코드 134참조)
        shtMdas	        :[],		//활동지 미디어구분(공통값분류코드 134참조)
        shtSbjt 	    :[],		//활동지 활동지 주제구분(공통값분류코드 135참조)
        shtSbjts 	    :[],		//활동지 활동지 주제구분(공통값분류코드 135참조)
        useYn 	        :'',    		//사용여부
        idxNm           :'',
        prgTyplist      :[],
        vCd38          : [],
        vCd133          : [],
        vCd134          : [],
        vCd135          : [],
        prgActshtlist   : [],
        prgActshtHeaders    : [
            { title: '구분', key: 'shtClcd', sortable: false, align: 'center', width: '150' },
            { title: '화일위치', key: 'fileLoc', sortable: false, align: 'center', width: '150' },
            { title: '대표여부', key: 'rprsYn', sortable: false, align: 'center', width: '150' },
            { title: '내용', key: 'cntnt', sortable: false, align: 'center', width: '300' },            
            { title: '순서', key: 'ord', sortable: false, align: 'center', width: '100' },
            { title: '처리', key: 'action', sortable: false, align: 'center', width: '150' },
        ],
        shtClcdItem     : [
            { valcdnm: '활동예제', valcd: '1'},
            { valcdnm: '활동지', valcd: '2'},
            { valcdnm: '첨부화일', valcd: '3'}
        ],
        fileLocItem     : [
            { valcdnm: 'NAVER', valcd: '1'},
            { valcdnm: 'YYS', valcd: '2'},
            { valcdnm: 'YOUTUBE', valcd: '3'},
        ],
        rprsYnItem     : [
            { valcdnm: '예', valcd: '1'},
            { valcdnm: '아니오', valcd: '2'},
        ],
        sltkey:0,
    })

    onMounted(() => {
        setPrgInf(props.psdObj)
    })

    watch(() => props.psdObj, (newValue) => {
        if(!newValue){
            setPrgInf(newValue)
        }
    })

    watch(() => dlgState.getSuccActBtn, (newValue) => {        
        if(dlgState.getDlgTyp == '115' && newValue)
            preSav()
    })

    watch(() => dlgState.getCnclActBtn, (newValue) => {
        if(dlgState.getDlgTyp == '115' && newValue)
            rmPrgInf(prgInf)
    })

    //프로그램 정보 상세 조회
    async function getPrgInf(obj) {
        prgInf.prgActshtlist.splice(0)
        await cmsApi.post('prgrm/selPrgInfInfo', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){
                    setData(response.data.result)
                }
                else{
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    //프로그램 활동지 다건 조회
    async function getPrgActshtlist(obj) {
        prgInf.prgActshtlist.splice(0)
        await cmsApi.post('prgrm/selPrgActshtlist', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){
                    setPrgActshtlist(response.data.result.list)
                }
                else{
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    // 저장
    async function prgInfSav() {
        await cmsApi.post('prgrm/insPrgInf', prgInf)
            .then((response) => {
                if(response.data.statusCode == '200'){
                    
                    noti.$patch({ stt: true, ttl: 'Info', cntnt: response.data.message, type: 'info', tmOut: 2500 })
                    dlgState.$patch({ isDlg: false, succActBtn: false })
                }
                else{
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })    
                        router.push({ name: 'LoginComp' })
                    }
                    else {
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                        dlgState.$patch({ succActBtn: false })
                    }
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    // 파일 업로드 (yys)
    async function yysUpload(frm) {
        await cmsApi.post('cmm/tmpFileUpload', frm)
            .then((response) => {
                if(response.data.statusCode == '200'){                    
                    let keyidx = prgInf.prgActshtlist.findIndex(v=> v.randkey == prgInf.sltkey) 

                    response.data.files.forEach(file => {
                        file.isDB = '2'
                    });

                    response.data.files.forEach(file => {
                        prgInf.prgActshtlist[keyidx].fileList.push(file)
                    });                               
                }
                else{
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    // 프로그램정보 삭제
    async function rmPrgInf(obj) {
        await cmsApi.post('prgrm/delPrgInf', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){
                    noti.$patch({ stt: true, ttl: 'Info', cntnt: response.data.message, type: 'info', tmOut: 2500 })
                    noti.$patch({ stt: true, ttl: 'Info', cntnt: response.data.message, type: 'info', tmOut: 2500 })
                    dlgState.$patch({ isDlg: false, succActBtn: false })
                }
                else{
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    // 프로그램활동지 삭제
    async function delPrgActsht(obj) {
        await cmsApi.post('prgrm/delPrgActsht', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){
                    let obj = {
                        prgInfPk : prgInf.prgInfPk
                    }
                    getPrgActshtlist(obj)
                }
                else{
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    function setPrgInf(obj){
        prgInf.prgTyplist =  obj.prgTyplist
        prgInf.vCd38 =  obj.vCd38
        prgInf.vCd133 =  obj.vCd133
        prgInf.vCd134 =  obj.vCd134
        prgInf.vCd135 =  obj.vCd135

        if(obj.prgInf != null){
            prgInf.prgInfPk         = obj.prgInf.prgInfPk
            getPrgInf(obj.prgInf)
        }

    }

    function setPrgTyp(){
        
        if(prgInf.prgTypMngPk != null) {
            let idx = prgInf.prgTyplist.findIndex(v=> v.prgTypMngPk == prgInf.prgTypMngPk)
            prgInf.idxNm = prgInf.prgTyplist[idx].idxNm
        }
        else prgInf.idxNm = ''
    }

    function addPrgActsht(){

        //랜덤key 생성
        let randkey = Math.floor(Math.random() * 1000);        
        let duplIdx = prgInf.prgActshtlist.findIndex(v=>v.randkey == randkey)        
        if(duplIdx > 0) randkey = Math.floor(Math.random() * 1000);

        let obj = {  
            randkey     : randkey,          
            shtClcd     :'1',
            ord         :'',
            rprsYn      :'2',
            fileLoc     :'1',
            fileNm      :'',
            filePath    :'',
            orgFileNm   :'',
            nvrUrl      :'',
            prgActshtPk : -1,
            thmUrl      : '',
            width       : '',
            height      : '',
            upFiles     : null,
            fileList    : [],
        }

        prgInf.prgActshtlist.push(obj)
    }

    function updFile(fileobj){
        prgInf.sltkey =  fileobj.randkey
        
        if(fileobj.upFiles.length != 0){
            let reg = /(.*?)\.(hwp|doc|docx|xls|xlsx|ppt|pptx|pdf|txt|jpg|jpeg|png|gif|bmp|tiff|tif|zip|rar|alz|egg|7z|mp4|avi|mkv|hwpx)$/
            let formData = new FormData()

            let isExp = true
            
            if(fileobj.upFiles != null){
                fileobj.upFiles.forEach(idx => {
                    if(idx.name.match(reg))
                        formData.append('files', idx)
                    else 
                        isExp = false
                })
                
                let cnt = 0
                for (let key of formData.keys()) {
                    if(key == 'files')
                        cnt += 1
                }

                if(!isExp) {
                    let msg = '확장자가 유효하지 않습니다. 데이터를 확인해주세요.(hwp|doc|docx|xls|xlsx|ppt|pptx|pdf|txt|jpg|jpeg|png|gif|bmp|tiff|tif|zip|rar|alz|egg|7z|mp4|avi|mkv|hwpx)'
                    noti.$patch({ stt: true, ttl: 'Warning', cntnt: msg, type: 'warning', tmOut: 2500 })
                    dlgState.$patch({ succActBtn: false })
                    fileobj.upFiles = null
                }

                if(cnt > 0) yysUpload(formData)                
            }
        } else {
            rmFiles(fileobj)
        }
    }

    function rmFiles(fileobj){
        fileobj.fileList.splice(0)
    }

    function preSav(){

        let msgList = []

        if(prgInf.fncRcvCds.length == 0) {
            prgInfForm.value = null
            msgList.push('기능회복훈련코드')
        }

        if(prgInf.shtTyps.length == 0) {
            prgInfForm.value = null
            msgList.push('활동지 유형구분')
        }

        if(prgInf.shtMdas.length == 0) 
        {
            prgInfForm.value = null
            msgList.push('활동지 미디어구분')
        }

        if(prgInf.shtSbjts.length == 0)
        {
            prgInfForm.value = null
            msgList.push('활동지 주제구분')
        } 
        
        if(prgInf.prgActshtlist.length > 0) {
            prgInf.prgActshtlist.forEach(itm => {
                if(itm.shtClcd == '') 
                {
                    prgInfForm.value = null
                    msgList.push('활동지 구분')
                }

                if(itm.ord == '') 
                {
                    prgInfForm.value = null
                    msgList.push('활동 순서')
                }

                if(itm.rprsYn == '') 
                {
                    msgList.push('대표여부')
                    prgInfForm.value = null
                }

                if(itm.fileLoc == '') 
                {
                    msgList.push('화일위치')
                    prgInfForm.value = null
                }


                if(itm.fileLoc == '1') {
                    if(itm.nvrUrl == '') 
                    {                
                        msgList.push('네이버URL')        
                        prgInfForm.value = null
                    }

                    if(itm.orgFileNm == '' && itm.shtClcd == '3') 
                    {
                        msgList.push('화일명')  
                        prgInfForm.value = null
                    }

                } else if(itm.fileLoc == '2') {                                       
                    if(itm.prgActshtPk == -1){
                        if(itm.fileList.length == 0) 
                        {
                            prgInfForm.value = null
                            msgList.push('프로그램정보 활동지(0)') 
                        }else {
                            itm.fileList.forEach(file => {
                                itm.fileNm         = file.fileNm
                                itm.filePath       = file.filePth
                                itm.orgFileNm       = file.orgFileNm
                            });
                        }
                    }
                } else if(itm.fileLoc == '3'){
                    let ytbId = onSearchYtbId(itm.nvrUrl)
                    
                    if(ytbId) itm.nvrUrl = ytbId
                }
            });
        } 

        if(prgInfForm.value){

            if(prgInf.fncRcvCd != null)
                prgInf.fncRcvCd.splice(0)
            else
                prgInf.fncRcvCd = []

            if(prgInf.shtTyp != null)
                prgInf.shtTyp.splice(0)
            else
                prgInf.shtTyp = []

            if(prgInf.shtMda != null)
                prgInf.shtMda.splice(0)
            else
                prgInf.shtMda = []

            if(prgInf.shtSbjt != null)
                prgInf.shtSbjt.splice(0)
            else
                prgInf.shtSbjt = []
            
            prgInf.fncRcvCds.forEach(itm => {
                prgInf.fncRcvCd.push(itm.valcd)
            });


            prgInf.shtTyps.forEach(itm => {
                prgInf.shtTyp.push(itm.valcd)
            });

            prgInf.shtMdas.forEach(itm => {
                prgInf.shtMda.push(itm.valcd)
            });

            prgInf.shtSbjts.forEach(itm => {
                prgInf.shtSbjt.push(itm.valcd)
            });

            
            let pass = true
            if(pass) prgInfSav()
        } else {
            let msg = '미입력 데이터 또는 규칙에 맞지않는 데이터를 확인해주세요.'
            
            msg += '('
            
            if(msgList.length > 0){
                msgList.forEach(str => {
                    msg += ' '+str+' '
                });
            }else{
                msg += ' 상단에 빨간 테두리 확인요망 '
            }

            msg += ')'
            
            noti.$patch({ stt: true, ttl: 'Warning', cntnt: msg, type: 'warning', tmOut: 2500 })
            dlgState.$patch({ succActBtn: false })
        }
    }

    function rmActsht(obj){
        if(obj.prgActshtPk == -1){
            let keyidx = prgInf.prgActshtlist.findIndex(v=> v.randkey == obj.sltkey) 
            prgInf.prgActshtlist.splice(keyidx , 1)
        } else {
            delPrgActsht(obj)
        }
    }

    //프로그램 활동지 DB SETTING
    function setData(obj){
        
        if(obj.prgInf != null){
            
            prgInf.prgTypMngPk 	    = obj.prgInf.prgTypMngPk       //프로그램유형관리 고유번호
            prgInf.prgNm 	        = obj.prgInf.prgNm    		    //프로그램명
            prgInf.plc 	            = ''    		                //장소
            prgInf.mc 	            = ''    			            //진행자
            prgInf.mcMmbrPk 	    = ''    	                    //진행자 구성원 고유번호
            prgInf.mtrl 	        = obj.prgInf.mtrl    		    //준비물
            prgInf.goal 	        = obj.prgInf.goal   		    //목표
            prgInf.prgCntnt 	    = obj.prgInf.prgCntnt   	    //프로그램내용
            prgInf.expEfc 	        = obj.prgInf.expEfc    		    //기대효과
            prgInf.fncRcvCd 	    = obj.prgInf.fncRcvCd    	    //기능회복훈련코드
            prgInf.fncRcvCds 	    = []    	                    //기능회복훈련코드
            prgInf.shtTyp 	        = obj.prgInf.shtTyp             //활동지 유형구분(공통값분류코드 133참조)
            prgInf.shtTyps	        = []		                    //활동지 유형구분(공통값분류코드 133참조)
            prgInf.shtMda 	        = obj.prgInf.shtMda             //활동지 미디어구분(공통값분류코드 134참조)
            prgInf.shtMdas	        = []		                    //활동지 미디어구분(공통값분류코드 134참조)
            prgInf.shtSbjt 	        = obj.prgInf.shtSbjt		                    //활동지 활동지 주제구분(공통값분류코드 135참조)
            prgInf.shtSbjts 	    = []		                    //활동지 활동지 주제구분(공통값분류코드 135참조)
            prgInf.useYn 	        = obj.prgInf.useYn              //사용여부
            prgInf.idxNm            = obj.prgInf.idxNm
            prgInf.nvrId            = obj.prgInf.nvrId
            prgInf.fileLocUrl       = obj.prgInf.fileLocUrl

            prgInf.fncRcvCd?.forEach(el => {                            
                prgInf.vCd38.forEach(cd => {
                    if(cd.valcd == el) prgInf.fncRcvCds.push(cd)
                });
            });

            prgInf.shtTyp?.forEach(el => {                            
                prgInf.vCd133.forEach(cd => {
                    if(cd.valcd == el) prgInf.shtTyps.push(cd)
                });
            });

            prgInf.shtMda?.forEach(el => {                            
                prgInf.vCd134.forEach(cd => {
                    if(cd.valcd == el) prgInf.shtMdas.push(cd)
                });
            });

            prgInf.shtSbjt?.forEach(el => {                            
                prgInf.vCd135.forEach(cd => {
                    if(cd.valcd == el) prgInf.shtSbjts.push(cd)
                });
            });
        }

        if(obj.actshtlist != null){
            setPrgActshtlist(obj.actshtlist)
        }

        if(prgInf.prgTypMngPk != null) setPrgTyp()
    }

    //프로그램 활동지 DB SETTING
    function setPrgActshtlist(list){
        list.forEach(el => {
            //랜덤key 생성
            let randkey = Math.floor(Math.random() * 1000);        
            let duplIdx = prgInf.prgActshtlist.findIndex(v=>v.randkey == randkey)        
            if(duplIdx > 0) randkey = Math.floor(Math.random() * 1000);

            let obj = {  
                randkey     : randkey,          
                shtClcd     : el.shtClcd,
                ord         : el.ord,
                rprsYn      : el.rprsYn,
                fileLoc     : el.fileLoc,
                fileNm      : el.fileNm,
                orgFileNm   : el.orgFileNm,
                filePath    : el.filePath,
                nvrUrl      : el.nvrUrl,
                prgActshtPk : el.prgActshtPk,
                thmUrl      : el.thmUrl,
                width       : el.width,
                height      : el.height,
                upFiles     : null,
                fileList    : [],
            }

            prgInf.prgActshtlist.push(obj)
        });
    }

    function onSearchYtbId(url){
        let videoIdFromURI = url.split('?v=').length > 1 ? url.split('?v=')[1].split('&')[0] : false;

        if(!videoIdFromURI) {
            videoIdFromURI = url.split('?si').length > 1 ? url.split('?si')[0].split('.be/')[1] : false

            if(!videoIdFromURI) {
                videoIdFromURI = url.split('.be/').length > 1 ? url.split('.be/')[1] : false
            }
        }
        
        return videoIdFromURI;        
    }

</script>