<template>
    <v-card
        rounded="lg">
        <v-row class="bg-blue107 align-center py-4 px-5" no-gutters>
            <span class="py-2 fnt1-2 font-weight-bold">세금계산서/현금영수증 발행</span>
            <v-spacer></v-spacer>
            <v-btn
                class="" @click="isClose"
                variant="plain" size="30" icon slim>
                <v-icon color="blue102" size="26">mdi-window-close</v-icon>
            </v-btn>
        </v-row>
        <v-divider></v-divider>

        <div class="overflow-y-auto px-8 py-5">
            <v-row class="pa-0" no-gutters>                
                <v-col class="" cols="6">            
                    <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">취소 공급가액</span>
                </v-col>
                <v-col class="" cols="6">            
                    <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">취소 부가세</span>
                </v-col>
            </v-row>
            <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
            <v-row class="pa-0" no-gutters>
                <v-col class="pl-2" cols="6">
                    {{ taxInvcInf.cnclAmt?.toLocaleString('ko-KR') }}&#40;결제금액 : {{taxInvcInf.osupplycosttotal?.toLocaleString('ko-KR')}}&#41;
                </v-col>
                <v-col class="pl-2" cols="6">
                    {{ taxInvcInf.cnclTax?.toLocaleString('ko-KR') }}&#40;결제부가세 : {{taxInvcInf.otaxtotal?.toLocaleString('ko-KR')}}&#41;
                </v-col>
            </v-row>  
            <v-divider class="my-2" color="white"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="" cols="6">            
                    <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">발급종류</span>
                </v-col>
            </v-row>
            <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
            <v-row class="pa-0" no-gutters>
                <v-col class="pl-2" cols="6">
                    <v-radio-group
                        v-model="taxInvcInf.taxInvcCd"
                        inline>
                        <v-radio class="pa-0 ma-0 mr-2 fnt0-8" label="세금계산서" value="1"></v-radio>
                        <v-radio class="pa-0 ma-0 fnt0-8" label="현금영수증" value="2"></v-radio>
                    </v-radio-group>
                </v-col>                
            </v-row>  
            <v-divider class="my-2" color="white"></v-divider>
            <v-row class="pa-0" no-gutters>                
                <v-col class="" cols="6">            
                    <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">공급가액</span>
                </v-col>
                <v-col class="" cols="6">            
                    <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">부가세</span>
                </v-col>
            </v-row>
            <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
            <v-row class="pa-0" no-gutters>
                <v-col class="pl-2" cols="6">
                    <v-text-field
                            v-model="taxInvcInf.supplycosttotal"
                            class="login-theme"
                            base-color="grey002" color="blue103"
                            density="compact" variant="outlined"                            
                            hide-details>
                        </v-text-field>
                </v-col>
                <v-col class="pl-2" cols="6">
                    <v-text-field
                            v-model="taxInvcInf.taxtotal"
                            class="login-theme"
                            base-color="grey002" color="blue103"
                            density="compact" variant="outlined"                            
                            hide-details>
                        </v-text-field>
                </v-col>
            </v-row>  
            <v-divider class="my-2" color="white"></v-divider>
            <div v-if="taxInvcInf.taxInvcCd == '2'">
                <v-row class="pa-0" no-gutters>
                    <v-col  class="" cols="6">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">용도</span>
                    </v-col> 
                    <v-col class="" cols="2">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">신청자명</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">휴대폰 번호/사업자 번호</span>
                    </v-col>                    
                </v-row>
                <v-divider class="mt-1-qrtr-1" color="white"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="pl-2" cols="6">
                        <v-radio-group
                            v-model="taxInvcInf.cashRcptCd"
                            inline>
                            <v-radio class="pa-0 ma-0 mr-2 fnt0-8" label="개인소독 공제용" value="1"></v-radio>
                            <v-radio class="pa-0 ma-0 fnt0-8" label="사업자 지출 증빙용" value="2"></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col class="pl-2" cols="2">
                        <v-text-field
                            v-model="taxInvcInf.ceo"
                            class="login-theme"
                            base-color="grey002" color="blue103"
                            density="compact" variant="outlined"                            
                            hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        <v-text-field
                            v-model="taxInvcInf.bsnsRgst"
                            class="login-theme"
                            base-color="grey002" color="blue103"
                            density="compact" variant="outlined"                            
                            hide-details>
                        </v-text-field>
                    </v-col>
                </v-row>
            </div>
            <div v-else>
                <v-row class="pa-0" no-gutters>
                    <v-col  class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">상호</span>
                    </v-col> 
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">업태</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">업종</span>
                    </v-col>                    
                </v-row>
                <v-divider class="mt-1-qrtr-1" color="white"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="pl-2" cols="4">
                        <v-text-field
                            v-model="taxInvcInf.cmpnNm"
                            class="login-theme"
                            base-color="grey002" color="blue103"
                            density="compact" variant="outlined"                            
                            hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        <v-text-field
                            v-model="taxInvcInf.bsnsCndtn"
                            class="login-theme"
                            base-color="grey002" color="blue103"
                            density="compact" variant="outlined"                            
                            hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        <v-text-field
                            v-model="taxInvcInf.bsnsItm"
                            class="login-theme"
                            base-color="grey002" color="blue103"
                            density="compact" variant="outlined"                            
                            hide-details>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-divider class="my-2" color="white"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col  class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">사업자 번호</span>
                    </v-col> 
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">대표자명</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">담당자명</span>
                    </v-col>                    
                </v-row>
                <v-divider class="mt-1-qrtr-1" color="white"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="pl-2" cols="4">
                        <v-text-field
                            v-model="taxInvcInf.bsnsRgst"
                            class="login-theme"
                            base-color="grey002" color="blue103"
                            density="compact" variant="outlined"                            
                            hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        <v-text-field
                            v-model="taxInvcInf.ceo"
                            class="login-theme"
                            base-color="grey002" color="blue103"
                            density="compact" variant="outlined"                            
                            hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col class="pl-2" cols="4">
                        <v-text-field
                            v-model="taxInvcInf.mngr"
                            class="login-theme"
                            base-color="grey002" color="blue103"
                            density="compact" variant="outlined"                            
                            hide-details>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-divider class="my-2" color="white"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col  class="" cols="6">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">전화번호</span>
                    </v-col> 
                    <v-col class="" cols="6">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">이메일</span>
                    </v-col>                                  
                </v-row>
                <v-divider class="mt-1-qrtr-1" color="white"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="pl-2 d-flex justify-start align-center" cols="6">
                        <span class="inline-block pr-2" style="width:100px;">
                            <v-text-field
                                v-model="taxInvcInf.tel1"
                                class="login-theme"
                                base-color="grey002" color="blue103"
                                density="compact" variant="outlined"                            
                                hide-details>
                            </v-text-field>
                        </span>
                        <span class="inline-block pr-2">-</span>
                        <span class="inline-block pr-2" style="width:100px;">
                            <v-text-field
                                v-model="taxInvcInf.tel2"
                                class="login-theme"
                                base-color="grey002" color="blue103"
                                density="compact" variant="outlined"                            
                                hide-details>
                            </v-text-field>
                        </span>
                        <span class="inline-block pr-2">-</span>
                        <span class="inline-block" style="width:100px;">
                            <v-text-field
                                v-model="taxInvcInf.tel3"
                                class="login-theme"
                                base-color="grey002" color="blue103"
                                density="compact" variant="outlined"                            
                                hide-details>
                            </v-text-field>
                        </span>
                    </v-col>
                    <v-col class="pl-2 d-flex justify-start align-center" cols="6">
                        <span class="inline-block pr-2" style="width:160px;">
                            <v-text-field
                                v-model="taxInvcInf.emailId"
                                class="login-theme"
                                base-color="grey002" color="blue103"
                                density="compact" variant="outlined"                            
                                hide-details>
                            </v-text-field>
                        </span>
                        <span class="inline-block pr-2">
                            @
                        </span>
                        <span class="inline-block" style="width:160px;">
                            <v-text-field
                                v-model="taxInvcInf.emailDmn"
                                class="login-theme"
                                base-color="grey002" color="blue103"
                                density="compact" variant="outlined"                            
                                hide-details>
                            </v-text-field>
                        </span>
                    </v-col>
                </v-row>
                <v-divider class="my-2" color="white"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col  class="" cols="12">
                        <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">주소</span>
                    </v-col>                                 
                </v-row>
                <v-divider class="mt-1-qrtr-1" color="white"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="pl-2 d-flex" cols="12">
                        <span class="inline-block pr-2" style="width:100px;">
                            <v-text-field
                                v-model="taxInvcInf.zip"
                                class="login-theme"
                                base-color="grey002" color="blue103"
                                density="compact" variant="outlined"                            
                                hide-details>
                            </v-text-field>
                        </span>
                        <span class="inline-block pr-2" style="width:300px;">
                            <v-text-field
                                v-model="taxInvcInf.addr"
                                class="login-theme"
                                base-color="grey002" color="blue103"
                                density="compact" variant="outlined"                            
                                hide-details>
                            </v-text-field>
                        </span>
                        <span class="inline-block " style="width:250px;">
                            <v-text-field
                                v-model="taxInvcInf.addrDtl"
                                class="login-theme"
                                base-color="grey002" color="blue103"
                                density="compact" variant="outlined"                            
                                hide-details>
                            </v-text-field>
                        </span>
                    </v-col>
                </v-row>
                <v-divider class="my-2" color="white"></v-divider>
            </div>
        </div>
        <v-divider></v-divider>
        <v-row class="py-4 px-5" no-gutters>
            <v-spacer></v-spacer>
            <v-btn                
                class="grdt-btn-blue001 text-white"
                @click="setInsTaxInvc"
                width="80" height="34" variant="elevated">
                <span class="fnt0-8 font-weight-black">저장</span>
            </v-btn>
        </v-row>
    </v-card>
</template>

<script setup>  
    import { reactive, onMounted, watch, getCurrentInstance} from 'vue'
    import { lgnInf, notiInf,  glbDlg,  } from "@/state";
    import { cmsApi } from '@/plugins/yysCmsApi'
    import { useRouter } from 'vue-router'

    const router = useRouter()
    const noti = notiInf()
    const lgn = lgnInf()
    const dlgInf = glbDlg()
    const props = defineProps(['psdObj'])
    const emit = defineEmits(['onClose'])

    const $moment = getCurrentInstance().appContext.config.globalProperties.$moment
    

    let taxInvcInf = reactive({
        fcltyNum            : '',
        yysPymHisPk         : 0,
        taxInvcCd	        : '', //세금계산서 발급코드(1:세금계산서, 2:현금영수증)
        prcClcd		        : '', //처리구분코드(1:요청,2:진행중,3:발급정상,4:발급오류)
        errMsg		        : '', //오류메세지
        cashRcptCd	        : '', //현금영수증 사용용도(1:개인소독 공제용, 2:사업자 지출 증빙용 )
        cmpnNm		        : '', //상호
        bsnsRgst	        : '', //사업자번호(개인소득공제용인 경우 휴대폰 번호)
        ceo			        : '', //대표자명
        bsnsCndtn	        : '', //업태
        bsnsItm		        : '', //업종
        tel1		        : '', //전화번호1
        tel2		        : '', //전화번호2
        tel3		        : '', //전화번호3
        zip			        : '', //사업장주소_우편번호
        addr		        : '', //사업장주소
        addrDtl		        : '', //사업장주소_상세주소
        mngr		        : '', //담당자명
        emailId		        : '', //이메일 ID
        emailDmn	        : '', //이메일 도메인
        mgtkey    	        : '', //파트너가 할당한 문서번호
        writedate	        : '', //작성일자
        supplycosttotal     : '',     //공급가액 합계
        taxtotal            : '', //세액 합계
        osupplycosttotal     : '',     //원 공급가액 합계
        otaxtotal            : '', //원 세액 합계
        totalamount         : '', //합계금액
        ntsconfirmnum       : '', //국세청승인번호				
        pymtSttCd	        : '', //결제상태코드(공통항목값분류코드 131)	         
        crtDt               : '',
        cntnt               : '',
        cntnt1              : '',
        cntnt2              : '',
    })

    onMounted(() => {
        setTaxInvcInf(props.psdObj)        
    })

    watch(() => props.psdObj, (newValue) => {
        setTaxInvcInf(newValue)   
    })

    watch(() => taxInvcInf.taxInvcCd, (newValue) => {
        if(newValue == '1') selFcltyInfInfo()
    })

    //기관정보 조회
    async function selFcltyInfInfo() {

        let obj = {
            fcltyNum : taxInvcInf.fcltyNum
        }

        await cmsApi.post('fclty/selFcltyInfInfo', obj)
        .then((response) => {
            
            if(response.data.statusCode == '200'){
                setFcltyInfInfo(response.data.result.fcltyInfo)
            }   
            else{
                dlgInf.$patch({ succActBtn: false })
                if(response.data.statusCode == '610'){
                    lgn.$patch({
                        account: '',
                        name: '',
                        level: '',
                        token: '',
                        key: '',
                    })
                    noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })    
                    router.push({ name: 'LoginComp' })
                }
                else
                    noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
            }
        })
        .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    } 

    //기관정보 조회
    async function setInsTaxInvc() {
        taxInvcInf.writedate = $moment().format('YYYYMMDD')
        taxInvcInf.prcClcd = '1'
        taxInvcInf.mgtkey = ''

        taxInvcInf.totalamount  = taxInvcInf.supplycosttotal + taxInvcInf.taxtotal

        await cmsApi.post('yyspym/insTaxInvc', taxInvcInf)
        .then((response) => {
            
            if(response.data.statusCode == '200'){
                noti.$patch({ stt: true, ttl: 'Info', cntnt: response.data.message, type: 'info', tmOut: 2500 })
                emit('onClose')
            }   
            else{
                dlgInf.$patch({ succActBtn: false })
                if(response.data.statusCode == '610'){
                    lgn.$patch({
                        account: '',
                        name: '',
                        level: '',
                        token: '',
                        key: '',
                    })
                    noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })    
                    router.push({ name: 'LoginComp' })
                }
                else
                    noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
            }
        })
        .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
        } 

    function isClose(){
        emit('onClose')
    }

    function setTaxInvcInf(obj){
        taxInvcInf.fcltyNum             = obj.fcltyNum
        taxInvcInf.yysPymHisPk          = obj.yysPymHisPk
        taxInvcInf.taxInvcCd	        = obj.taxInvcCd //세금계산서 발급코드(1:세금계산서, 2:현금영수증)
        taxInvcInf.prcClcd		        = obj.prcClcd //처리구분코드(1:요청,2:진행중,3:발급정상,4:발급오류)
        taxInvcInf.errMsg		        = obj.errMsg //오류메세지
        taxInvcInf.cashRcptCd	        = obj.cashRcptCd //현금영수증 사용용도(1:개인소독 공제용, 2:사업자 지출 증빙용 )
        taxInvcInf.cmpnNm		        = obj.cmpnNm //상호
        taxInvcInf.bsnsRgst	            = obj.bsnsRgst //사업자번호(개인소득공제용인 경우 휴대폰 번호)
        taxInvcInf.ceo			        = obj.ceo //대표자명
        taxInvcInf.bsnsCndtn	        = obj.bsnsCndtn //업태
        taxInvcInf.bsnsItm		        = obj.bsnsItm //업종
        taxInvcInf.tel1		            = obj.tel1 //전화번호1
        taxInvcInf.tel2		            = obj.tel2 //전화번호2
        taxInvcInf.tel3		            = obj.tel3 //전화번호3
        taxInvcInf.zip			        = obj.zip //사업장주소_우편번호
        taxInvcInf.addr		            = obj.addr //사업장주소
        taxInvcInf.addrDtl		        = obj.addrDtl //사업장주소_상세주소
        taxInvcInf.mngr		            = obj.mngr //담당자명
        taxInvcInf.emailId		        = obj.emailId //이메일 ID
        taxInvcInf.emailDmn	            = obj.emailDmn //이메일 도메인
        taxInvcInf.mgtkey    	        = obj.mgtkey //파트너가 할당한 문서번호
        taxInvcInf.writedate	        = obj.writedate //작성일자
        taxInvcInf.osupplycosttotal     = obj.supplycosttotal     //공급가액 합계
        taxInvcInf.otaxtotal            = obj.taxtotal //세액 합계
        taxInvcInf.supplycosttotal      = obj.cnclAmt     //공급가액 합계
        taxInvcInf.taxtotal             = obj.cnclTax //세액 합계
        taxInvcInf.totalamount          = obj.totalamount //합계금액
        taxInvcInf.ntsconfirmnum        = obj.ntsconfirmnum //국세청승인번호				
        taxInvcInf.pymtSttCd	        = obj.pymtSttCd //결제상태코드(공통항목값분류코드 131)	         
        taxInvcInf.crtDt                = obj.crtDt
        taxInvcInf.cnclAmt              = obj.cnclAmt
        taxInvcInf.cnclTax              = obj.cnclTax
        taxInvcInf.cntnt                = obj.cntnt
        taxInvcInf.cntnt1               = obj.cntnt1
        taxInvcInf.cntnt2               = obj.cntnt2
    }

    function setFcltyInfInfo(obj){
        taxInvcInf.cmpnNm		        = obj.fcltyClnm //상호
        taxInvcInf.bsnsRgst	            = obj.bsnsRgst //사업자번호(개인소득공제용인 경우 휴대폰 번호)
        taxInvcInf.ceo			        = obj.ceo //대표자명
        taxInvcInf.bsnsCndtn	        = obj.bsnsCndtn //업태
        taxInvcInf.bsnsItm		        = obj.bsnsItm //업종
        taxInvcInf.tel1		            = obj.cllTel1 //전화번호1
        taxInvcInf.tel2		            = obj.cllTel2 //전화번호2
        taxInvcInf.tel3		            = obj.cllTel3 //전화번호3
        taxInvcInf.zip			        = obj.zip //사업장주소_우편번호
        taxInvcInf.addr		            = obj.addr //사업장주소
        taxInvcInf.addrDtl		        = obj.addrDtl //사업장주소_상세주소
        taxInvcInf.mngr		            = obj.mngr //담당자명
        taxInvcInf.emailId		        = obj.emailId //이메일 ID
        taxInvcInf.emailDmn	            = obj.emailDmn //이메일 도메인
    }

</script>