<template>
    <v-dialog
        v-model="dlgInf.isMdl"
        :width="dlgInf.width">
        <v-card
            rounded="lg">
            <v-row class="bg-blue107 align-center py-4 px-5" no-gutters>
                <span class="py-2 fnt1-2 font-weight-bold">{{ dlgInf.title }}</span>
                <v-spacer></v-spacer>
                <v-btn
                    class="" @click="dlgInf.isMdl = false"
                    variant="plain" size="30" icon slim>
                    <v-icon color="blue102" size="26">mdi-window-close</v-icon>
                </v-btn>
            </v-row>
            <v-divider></v-divider>

            <div class="overflow-y-auto px-8 py-5">
                <News v-if="dlgInf.typ == '101'" :psdObj="dlgInf.obj"></News>
                <Mnl v-else-if="dlgInf.typ== '103'" :psdObj="dlgInf.obj"></Mnl>
                <MnlCtgry v-else-if="dlgInf.typ== '104'" :psdObj="dlgInf.obj"></MnlCtgry>
                <FcltyEmpMdl v-else-if="dlgInf.typ== '105'" :psdObj="dlgInf.obj"></FcltyEmpMdl>
                <FcltyInfMdl v-else-if="dlgInf.typ== '110'" :psdObj="dlgInf.obj"></FcltyInfMdl>
                <FcltyPymInfMdl v-else-if="dlgInf.typ== '111'" :psdObj="dlgInf.obj"></FcltyPymInfMdl>         
                <BbsMdl v-else-if="dlgInf.typ== '106'" :psdObj="dlgInf.obj"></BbsMdl>
                <BbsCmntMdl v-else-if="dlgInf.typ== '107'" :psdObj="dlgInf.obj"></BbsCmntMdl>
                <ErrLogMdl v-else-if="dlgInf.typ== '108'" :psdObj="dlgInf.obj"></ErrLogMdl>
                <IntrdMdl v-else-if="dlgInf.typ== '109'" :psdObj="dlgInf.obj"></IntrdMdl>
                <PymtSttEditMdl v-else-if="dlgInf.typ== '112'" :psdObj="dlgInf.obj"></PymtSttEditMdl>
                <PymtYysRfndMdl v-else-if="dlgInf.typ== '113'" :psdObj="dlgInf.obj"></PymtYysRfndMdl>
                <PymTaxInvcMdl v-else-if="dlgInf.typ== '114'" :psdObj="dlgInf.obj"></PymTaxInvcMdl>
                <PrgInfMdl v-else-if="dlgInf.typ== '115'" :psdObj="dlgInf.obj"></PrgInfMdl>
                <PrgActshtMdl v-else-if="dlgInf.typ== '116'" :psdObj="dlgInf.obj"></PrgActshtMdl>
            </div>

            <v-divider></v-divider>
            <v-row class="py-4 px-5" no-gutters>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="dlgInf.isCncl"
                    class="grdt-btn-grey001 mr-4"
                    width="80" height="34" variant="elevated"
                    @click="dlgState.clickCncl()">
                    <span class="fnt0-8 text-grey101 font-weight-black">삭제</span>
                </v-btn>
                <v-btn
                    v-if="dlgInf.isSucc"
                    class="grdt-btn-blue001 text-white"
                    width="80" height="34" variant="elevated"
                    @click="dlgState.clickSucc()">
                    <span class="fnt0-8 font-weight-black">저장</span>
                </v-btn>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script setup>
    import News from '@/components/yys/NewsMdl.vue'         // typ: 101 - YYS 전체공지
    import Mnl from '@/components/yys/MnlMdl.vue'           // typ: 103 - YYS 메뉴얼 등록
    import MnlCtgry from '@/components/yys/MnlCtgryMdl.vue'           // typ: 104 - YYS 메뉴얼 분류 관리
    import FcltyEmpMdl from '@/components/yys/FcltyEmpMdl.vue'        // typ: 105 - YYS 시설그룹관리(직원권한설정)
    import FcltyInfMdl from '@/components/yys/FcltyInfMdl.vue'        // typ: 110 - YYS 기관기본정보 조회
    import FcltyPymInfMdl from '@/components/yys/FcltyPymInfMdl.vue'        // typ: 111 - YYS 기관 결제내역 관리
    import BbsMdl from '@/components/yys/BbsMdl.vue'                  // typ: 106 - BBS 게시글 등록 및 수정
    import BbsCmntMdl from '@/components/yys/BbsCmntMdl.vue'                  // typ: 107 - BBS 댓글 등록 및 수정
    import ErrLogMdl from '@/components/yys/ErrLogMdl.vue'                  // typ: 108 - 시스템 오류
    import IntrdMdl from '@/components/yys/IntrdMdl.vue'                  // typ: 109 - 도입문의
    import PymtSttEditMdl from '@/components/yys/PymtSttEditMdl.vue'                  // typ: 112 - 결제상태 변경
    import PymtYysRfndMdl from '@/components/yys/PymtYysRfndMdl.vue'                  // typ: 113 - 환불정보 저장/수정
    import PymTaxInvcMdl from '@/components/yys/PymtYysRfndMdl.vue'                  // typ: 114 - 무통장, 계좌이체 계산서 추가
    import PrgInfMdl from '@/components/yys/PrgInfMdl.vue'                  // typ: 115 - 프로그램 정보 추가
    import PrgActshtMdl from '@/components/yys/PrgActshtMdl.vue'                  // typ: 116 - 프로그램 활동지 조회
    
    import { reactive, onMounted, watch } from 'vue'
    import { displayInfStore, glbDlg } from "@/state";

    const dlgState = glbDlg()

    let dsply = reactive({
        width: 0,
        height: 0,
    })

    let dlgInf = reactive({
        width: '',
        title: '',
        typ: '',
        obj: null,
        isMdl: false,
        isCncl: false,
        isSucc: false,
    })

    onMounted(() => {
        dsply.width = displayInfStore().width
        dsply.height = displayInfStore().height
        dlgInf.width = dlgState.getDlgWth
        dlgInf.title = dlgState.getDlgTtl
        dlgInf.typ = dlgState.getDlgTyp
        if(dlgState.getPsdObj != null)
            dlgInf.obj = dlgState.getPsdObj
        dlgInf.isMdl = dlgState.getDlgStt
        dlgInf.isCncl = dlgState.getCnclBtn
        dlgInf.isSucc = dlgState.getSuccBtn
    })

    watch(() => dlgState.getDlgStt, (newValue) => {
        dlgInf.isMdl = newValue
    })
    watch(() => dlgState.getDlgWth, (newValue) => {
        dlgInf.width = newValue
    })
    watch(() => dlgState.getDlgTyp, (newValue) => {
        dlgInf.typ = newValue
    })
    watch(() => dlgState.getDlgTtl, (newValue) => {
        dlgInf.title = newValue
    })
    watch(() => dlgState.getPsdObj, (newValue) => {
        if(newValue != null)
            dlgInf.obj = newValue
        else{
            dlgInf.obj = null
            dlgState.$patch({ psdObj: null })
        }
    })
    watch(() => dlgInf.isMdl, (newValue) => {
        if(newValue == false)
            dlgState.$patch({ isDlg: newValue })
    })
    watch(() => dlgState.getCnclBtn, (newValue) => {
        dlgInf.isCncl = newValue
    })
    watch(() => dlgState.getSuccBtn, (newValue) => {
        dlgInf.isSucc = newValue
    })

</script>