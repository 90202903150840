<template>
    <v-form v-model="pymEditForm">        
        <v-row class="pa-0" no-gutters>
            <v-col class="" cols="4">
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">기관번호</span>
            </v-col>
            <v-col class="" cols="4">
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">이용서비스</span>
            </v-col>
            <v-col  class="" cols="4">
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">결제내역</span>
            </v-col> 
        </v-row>
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
        <v-row class="pa-0" no-gutters>
            <v-col class="pl-2" cols="4">
                {{ pymHisInf.fcltyNum }}
            </v-col>
            <v-col class="pl-2" cols="4">
                {{ pymHisInf.srvcClcdNm }}
            </v-col>
            <v-col class="pl-2" cols="4">
                {{ pymHisInf.cntnt }}
            </v-col>
        </v-row>  
        <v-divider class="my-2" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="" cols="4">
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">결제상태&#40;결제일시&#41;</span>
            </v-col>
            <v-col class="" cols="4">
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">결제수단</span>
            </v-col>
            <v-col  class="" cols="4">
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">결제금액&#40;원&#41;</span>
            </v-col> 
        </v-row>
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
        <v-row class="pa-0" no-gutters>
            <v-col class="pl-2" cols="4">
                {{ pymHisInf.pymtSttNm }}&#40;{{ pymHisInf.pymttime }}&#41;
            </v-col>
            <v-col class="pl-2" cols="4">
                {{ pymHisInf.pymtMthdCdNm }}
            </v-col>
            <v-col class="pl-2" cols="4">
                {{ pymHisInf.pymtAmt.toLocaleString('ko-KR') }}
            </v-col>
        </v-row>
        <v-divider class="my-2" color="white"></v-divider>   
        <v-row class="pa-0" no-gutters>
            <v-col class="" cols="4">
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">환불일자</span>
            </v-col>
            <v-col class="" cols="4">
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">환불일시&#40;시:분&#41;</span>
            </v-col>
            <v-col class="" cols="4">
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">환불금액</span>
            </v-col>
        </v-row>
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
        <v-row class="pa-0" no-gutters>
            <v-col class="pl-2" cols="4">                
                <CmmDtPckr v-model="rfndHisInfo.rfndDt" :required="true"></CmmDtPckr>
            </v-col>
            <v-col class="pl-2" cols="4">
                <v-text-field
                    v-model="rfndHisInfo.rfndDttm"                                    
                    class="login-theme"
                    base-color="grey002" color="blue103"
                    density="compact" variant="outlined"
                    type="text"                                    
                    hide-details>
                </v-text-field>
            </v-col>
            <v-col class="pl-2" cols="4">
                <v-text-field
                    v-model="rfndHisInfo.rfndAmt"                                    
                    class="login-theme"
                    base-color="grey002" color="blue103"
                    density="compact" variant="outlined"
                    type="number"                                    
                    hide-details>
                </v-text-field>
            </v-col>
        </v-row>
        <v-divider class="my-2" color="white"></v-divider>   
        <v-row class="pa-0" no-gutters>
            <v-col class="" cols="4">
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">환불은행명</span>
            </v-col>
            <v-col class="" cols="8">
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">환불은행 계좌번호</span>
            </v-col>
        </v-row>
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
        <v-row class="pa-0" no-gutters>
           
            <v-col class="pl-2" cols="4">
                <v-text-field
                    v-model="rfndHisInfo.rfndBnkNm"                                    
                    class="login-theme"
                    base-color="grey002" color="blue103"
                    density="compact" variant="outlined"
                    type="text"                                    
                    hide-details>
                </v-text-field>
            </v-col>
            <v-col class="pl-2" cols="8">
                <v-text-field
                    v-model="rfndHisInfo.rfndBnkAcct"                                    
                    class="login-theme"
                    base-color="grey002" color="blue103"
                    density="compact" variant="outlined"
                    type="text"                                    
                    hide-details>
                </v-text-field>
            </v-col>
        </v-row>
        <v-divider class="my-2" color="white"></v-divider>   
        <v-row class="pa-0" no-gutters>
            <v-col class="" cols="4">
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">환불은행 예금주명</span>
            </v-col>
            <v-col class="" cols="8">
                <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">비고</span>
            </v-col>
        </v-row>
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider>   
        <v-row class="pa-0" no-gutters>
            <v-col class="pl-2" cols="4">
                <v-text-field
                    v-model="rfndHisInfo.rfndBnkHld"                                    
                    class="login-theme"
                    base-color="grey002" color="blue103"
                    density="compact" variant="outlined"
                    type="text"                                    
                    hide-details>
                </v-text-field>
            </v-col>
            <v-col class="pl-2" cols="8">
                <v-text-field
                    v-model="rfndHisInfo.rmrks"                                    
                    class="login-theme"
                    base-color="grey002" color="blue103"
                    density="compact" variant="outlined"
                    type="text"                                    
                    hide-details>
                </v-text-field>
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup>
    import { reactive, onMounted, watch, getCurrentInstance, ref} from 'vue'
    import { lgnInf, notiInf, glbDlg} from "@/state";
    import { cmsApi } from '@/plugins/yysCmsApi'
    import { useRouter } from 'vue-router'

    import CmmDtPckr from '@/components/commons/CmmDtPckr.vue'

    const props = defineProps(['psdObj'])      
    const dlgState  = glbDlg()
    const router = useRouter()
    const noti = notiInf()
    const lgn = lgnInf()

    const $moment = getCurrentInstance().appContext.config.globalProperties.$moment

    
    const pymEditForm   = ref(false)

    let rfndHisInfo = reactive({
        fcltyNum        : '',
        yysRfndHisPk    : 0,
        rfndDt          : '',    		//환불일자
        rfndDttm        : '',    	//환불일시
        pymtSttCd       : '',    	//결제상태코드(공통항목값분류코드 131참조)
        rfndAmt         : 0,    	//환불금액
        rfndBnkNm       : '',    	//환불은행명
        rfndBnkAcct     : '',    //환불은행 계좌번호
        rfndBnkHld      : '',    	//환불은행 예금주명
        rmrks           : '',    		//비고
        cnclYn          : '',    		//취소여부
        yysPymHisPk     : ''
    })

    let pymHisInf = reactive({
        yysPymHisPk     : 0,
        cntnt           : '',
        cntnt1          : '',
        cntnt2          : '',
        dpstrNm         : '',
        fcltyClnm       : '',
        fcltyNum        : '',
        mgtkey          : '',
        mjrPrdCd        : '',
        prcClcd         : '',
        pymtAmt         : '',
        pymtCnclCd      : '',
        pymtCnclDt      : '',
        pymtCnclDttm    : '',
        pymtCnclNm      : '',
        pymtDt          : '',
        pymtDttm        : '',
        pymtMthdCd      : '',
        pymtMthdCdNm    : '',
        pymtSttCd       : '',
        pymtSttNm       : '',
        pymttime        : '',
        rcid            : '',
        rmrks           : '',
        srvcClcd        : '',
        srvcClcdNm      : '',
        taxInvcCd       : '',
        taxInvcPk       : 0,
        trno            : '',
   })

  

    onMounted(() => {
        setPymtHisInf(props.psdObj)
        getysRfndHisInfo()
    })

    watch(() => props.psdObj, (newValue) => {

        if(!newValue){
            setPymtHisInf(newValue)
            getysRfndHisInfo()
        }
        
    })

    watch(() => dlgState.getSuccActBtn, (newValue) => {
        if(dlgState.getDlgTyp == '113' && newValue)
            preYysRfndHis()
    })

    async function getysRfndHisInfo() {      
        let obj = {
            fcltyNum : pymHisInf.fcltyNum,
            yysPymHisPk : pymHisInf.yysPymHisPk
        } 
        await cmsApi.post('yyspym/selYysRfndHisInfo', obj)
            .then((response) => {
                
                if(response.data.statusCode == '200'){   
                    
                    if(response.data.result != null){
                        rfndHisInfo.yysRfndHisPk    = response.data.result.yysRfndHisPk
                        
                        if(response.data.result.rfndDt != null && response.data.result.rfndDt != ''){
                            rfndHisInfo.rfndDt = $moment(response.data.result.rfndDt, 'YYYYMMDD').format('YYYY-MM-DD')
                        }

                        rfndHisInfo.rfndDttm        = response.data.result.rfndDttm    	    //환불일시
                        rfndHisInfo.pymtSttCd       = response.data.result.pymtSttCd    	    //결제상태코드(공통항목값분류코드 131참조)
                        rfndHisInfo.rfndAmt         = response.data.result.rfndAmt    	    //환불금액
                        rfndHisInfo.rfndBnkNm       = response.data.result.rfndBnkNm    	    //환불은행명
                        rfndHisInfo.rfndBnkAcct     = response.data.result.rfndBnkAcct       //환불은행 계좌번호
                        rfndHisInfo.rfndBnkHld      = response.data.result.rfndBnkHld    	//환불은행 예금주명
                        rfndHisInfo.rmrks           = response.data.result.rmrks    		    //비고
                        rfndHisInfo.cnclYn          = response.data.result.cnclYn    		//취소여부
                    }
                   
                }
                else{
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }


    async function setYysRfndHis(obj) {

        await cmsApi.post('yyspym/insYysRfndHis', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){
                    noti.$patch({ stt: true, ttl: 'Info', cntnt: response.data.message, type: 'info', tmOut: 2500 })
                    dlgState.$patch({ isDlg: false, succActBtn: false })
                }
                else{
                    dlgState.$patch({ succActBtn: false })
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }



   
    function setPymtHisInf(obj) {
        pymHisInf.yysPymHisPk       = obj.yysPymHisPk
        pymHisInf.cntnt             = obj.cntnt
        pymHisInf.cntnt1            = obj.cntnt1
        pymHisInf.cntnt2            = obj.cntnt2
        pymHisInf.dpstrNm           = obj.dpstrNm
        pymHisInf.fcltyClnm         = obj.fcltyClnm
        pymHisInf.fcltyNum          = obj.fcltyNum
        pymHisInf.mgtkey            = obj.mgtkey
        pymHisInf.mjrPrdCd          = obj.mjrPrdCd
        pymHisInf.prcClcd           = obj.prcClcd
        pymHisInf.pymtAmt           = obj.pymtAmt
        pymHisInf.pymtCnclCd        = obj.pymtCnclCd
        pymHisInf.pymtCnclDt        = obj.pymtCnclDt
        pymHisInf.pymtCnclDttm      = obj.pymtCnclDttm
        pymHisInf.pymtCnclNm        = obj.pymtCnclNm
        pymHisInf.pymtDt            = obj.pymtDt
        pymHisInf.pymtDttm          = obj.pymtDttm
        pymHisInf.pymtMthdCd        = obj.pymtMthdCd
        pymHisInf.pymtMthdCdNm      = obj.pymtMthdCdNm
        pymHisInf.pymtSttCd         = obj.pymtSttCd
        pymHisInf.pymtSttNm         = obj.pymtSttNm
        pymHisInf.pymttime          = obj.pymttime
        pymHisInf.rcid              = obj.rcid
        pymHisInf.rmrks             = obj.rmrks
        pymHisInf.srvcClcd          = obj.srvcClcd
        pymHisInf.srvcClcdNm        = obj.srvcClcdNm
        pymHisInf.taxInvcCd         = obj.taxInvcCd
        pymHisInf.taxInvcPk         = obj.taxInvcPk
        pymHisInf.trno              = obj.trno
        pymHisInf.yysUseLstPk       = obj.yysUseLstPk

        rfndHisInfo.fcltyNum        = obj.fcltyNum
        rfndHisInfo.yysPymHisPk     = obj.yysPymHisPk
    }


    function preYysRfndHis(){
        let pass = true
        let cntnt = '';

        if(pymHisInf.pymtAmt < rfndHisInfo.rfndAmt || rfndHisInfo.rfndAmt < 1){
            cntnt = '환불금액을 확인해주세요. (결제금액보다 클수 없으며, 금액을 0이상 입력해주세요.)'
            pass = false
        } 

        if(pass) { 
            let obj = {
                fcltyNum        : pymHisInf.fcltyNum,
                yysRfndHisPk    : rfndHisInfo.yysRfndHisPk,
                rfndDt          : '',    		//환불일자
                rfndDttm        : rfndHisInfo.rfndDttm,    	//환불일시
                pymtSttCd       : '5',    	//결제상태코드(공통항목값분류코드 131참조)
                rfndAmt         : rfndHisInfo.rfndAmt,    	//환불금액
                rfndBnkNm       : rfndHisInfo.rfndBnkNm,    	//환불은행명
                rfndBnkAcct     : rfndHisInfo.rfndBnkAcct,    //환불은행 계좌번호
                rfndBnkHld      : rfndHisInfo.rfndBnkHld,    	//환불은행 예금주명
                rmrks           : rfndHisInfo.rmrks,    		//비고
                cnclYn          : '2',    		//취소여부
                yysPymHisPk     : pymHisInf.yysPymHisPk,
                srvcClcd        : pymHisInf.srvcClcd
            }

            if(rfndHisInfo.rfndDt != '') obj.rfndDt = $moment(rfndHisInfo.rfndDt, 'YYYY-MM-DD').format('YYYYMMDD')
    
            setYysRfndHis(obj)
        }  else {
            dlgState.$patch({ succActBtn: false })
            noti.$patch({ stt: true, ttl: 'Error', cntnt: cntnt, type: 'error', tmOut: 3000 })
        }
    }

</script>